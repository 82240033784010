<template>
  <div>
    <loading-overlay :overlay="loading"></loading-overlay>

    <v-dialog v-model="dialogAltaPsp" max-width="600px">
      <v-card>
        <v-card-title class="headline primary">
          <v-icon left color="white">add</v-icon>
          <span style="color: white; font-weight: bold">Agregar Nuevo PSP</span>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="formData.razon_social"
              label="Razón Social"
              outlined
              dense
              :rules="[rules.required]"
              required
            ></v-text-field>

            <v-text-field
              v-model="formData.rfc"
              label="RFC"
              outlined
              dense
              :rules="[rules.required, rules.unique]"
              required
            ></v-text-field>

            <v-text-field
              v-model="formData.responsable"
              label="Responsable"
              outlined
              dense
              :rules="[rules.required]"
              required
            ></v-text-field>

            <v-text-field
              v-model="formData.correo_responsable"
              label="Correo Responsable"
              outlined
              dense
              :rules="[rules.required, rules.email]"
              required
            ></v-text-field>

            <v-select
              v-model="formData.tipo_psp"
              :items="['Moral', 'Fisica']"
              label="Tipo PSP"
              outlined
              dense
              :rules="[rules.required]"
              required
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAltaPsp = false">
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            :loading="loading"
            :disabled="loading"
            text
            @click="guardarPsp"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDocFinanciero" max-width="600px">
      <v-card>
        <v-card-title class="headline primary">
          <v-icon left color="white">add</v-icon>
          <span style="color: white; font-weight: bold"
            >Agregar Documentos Financieros</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-form ref="form_doc_financera" v-model="valid">
            <!-- Declaración Anual -->
            <div class="mb-4">
              <template v-if="doc_financiera?.declaracion_anual_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Declaración Anual</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_financiera?.declaracion_anual_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocFinanc('declaracion')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Declaración Anual"
                  v-model="formDataDocFinanciera.declaracion_anual_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Estado de Cuenta -->
            <div class="mb-4">
              <template v-if="doc_financiera?.estado_cuenta_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Estado de Cuenta</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_financiera?.estado_cuenta_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      class="mr-2"
                      fab
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocFinanc('cuenta')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Estado de Cuenta"
                  v-model="formDataDocFinanciera.estado_cuenta_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Estado Financiero -->
            <div class="mb-4">
              <template v-if="doc_financiera?.estado_financiero_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Estado Financiero</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_financiera?.estado_financiero_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocFinanc('financiero')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Estado Financiero"
                  v-model="formDataDocFinanciera.estado_financiero_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDocFinanciero = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="blue darken-1"
            :loading="loading"
            :disabled="loading"
            @click="guardarDocFinanciera"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDocTecnica" max-width="600px">
      <v-card>
        <v-card-title class="headline primary">
          <v-icon left color="white">add</v-icon>
          <span style="color: white; font-weight: bold; font-size: 15px"
            >Documentos que acrediten la Capacidad Técnica y Administrativa</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-form ref="form_doc_tecnica" v-model="valid">
            <!-- Relación de equipos -->
            <div class="mb-4">
              <template v-if="doc_tecnica?.relacion_equipo_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Relación de equipos con el que cuenta la empresa</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_tecnica?.relacion_equipo_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocTec('equipos')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Relación de equipos con el que cuenta la empresa"
                  v-model="formDataDocTecnica.relacion_equipo_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Relación de vehiculos -->
            <div class="mb-4">
              <template v-if="doc_tecnica?.relacion_vehiculos_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Relación de vehiculos para ingresar u operar</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_tecnica?.relacion_vehiculos_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      class="mr-2"
                      fab
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocTec('vehiculos')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Relación de vehiculos para ingresar u operar"
                  v-model="formDataDocTecnica.relacion_vehiculos_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!--  Personal -->
            <div class="mb-4">
              <template v-if="doc_tecnica?.relacion_personal_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span
                      >Organigrama de la empresa relacionada con los servicios
                      prestados</span
                    >
                  </div>
                  <div>
                    <v-btn
                      :href="doc_tecnica?.relacion_personal_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocTec('personal')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Organigrama de la empresa relacionada con los servicios
                      prestados"
                  v-model="formDataDocTecnica.relacion_personal_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Cartas de recomendación -->
            <div class="mb-4">
              <template v-if="doc_tecnica?.cartas_recomendacion_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Cartas de recomendación</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_tecnica?.cartas_recomendacion_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocTec('cartas')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Cartas de recomendación"
                  v-model="formDataDocTecnica.cartas_recomendacion_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Registro patronal personal -->
            <div class="mb-4">
              <template v-if="doc_tecnica?.registro_imss_personal_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Registro obrero patronal de los trabajadores</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_tecnica?.registro_imss_personal_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocTec('obrero_trabadores')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Registro obrero patronal de los trabajadores"
                  v-model="formDataDocTecnica.registro_imss_personal_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Registro patronal personal temporal -->
            <div class="mb-4">
              <template v-if="doc_tecnica?.registro_imss_personal_temporales_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Registro obrero patronal de los trabajadores temporales</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_tecnica?.registro_imss_personal_temporales_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocTec('obrero_trabadores_temporal')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Registro obrero patronal de los trabajadores temporales"
                  v-model="formDataDocTecnica.registro_imss_personal_temporales_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDocTecnica = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="blue darken-1"
            :loading="loading"
            :disabled="loading"
            @click="guardarDocTecnica"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDocCartas" max-width="600px">
      <v-card>
        <v-card-title class="headline primary">
          <v-icon left color="white">add</v-icon>
          <span style="color: white; font-weight: bold"
            >Agregar Cartas de compromiso</span
          >
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-form ref="form_doc_cartas" v-model="valid">
            <!--Anexo A -->
            <div class="mb-4">
              <template v-if="doc_cartas?.anexo_a">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Anexo A</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_cartas?.anexo_a"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocCartas('a')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Anexo A"
                  v-model="formDataCartas.anexo_a"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Anexo B -->
            <div class="mb-4">
              <template v-if="doc_cartas?.anexo_b">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Anexo B</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_cartas?.anexo_b"
                      target="_blank"
                      color="primary"
                      x-small
                      class="mr-2"
                      fab
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocCartas('b')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Anexo B"
                  v-model="formDataCartas.anexo_b"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Anexo C -->
            <div class="mb-4">
              <template v-if="doc_cartas?.anexo_c">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Anexo C</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_cartas?.anexo_c"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocCartas('c')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Anexo C"
                  v-model="formDataCartas.anexo_c"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <div class="mb-4">
              <template v-if="doc_cartas?.anexo_d">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Anexo D</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_cartas?.anexo_d"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocCartas('d')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Anexo D"
                  v-model="formDataCartas.anexo_d"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDocFinanciero = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="blue darken-1"
            :loading="loading"
            :disabled="loading"
            @click="guardarDocCartas"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDocGeneral" max-width="600px">
      <v-card>
        <v-card-title class="headline primary">
          <v-icon left color="white">add</v-icon>
          <span style="color: white; font-weight: bold">Documentación Personal</span>
        </v-card-title>
        <v-divider></v-divider>
        {{ formDataDocGeneral.cif_adj }}
        <br />
        <v-card-text>
          <v-form ref="form_doc_cartas" v-model="valid">
            <!--Anexo A -->
            <div class="mb-4">
              <template v-if="doc_general?.rfc_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Copia del RFC</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.rfc_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocGeneral('rfc')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Copia del RFC"
                  v-model="formDataDocGeneral.rfc_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Anexo B -->
            <div class="mb-4">
              <template v-if="doc_general?.curriculum_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Curriuculum de la empresa</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.curriculum_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      class="mr-2"
                      fab
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocGeneral('curriculum')"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Curriculum de la empresa"
                  v-model="formDataDocGeneral.curriculum_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <!-- Anexo C -->
            <div class="mb-4">
              <template v-if="doc_general?.cif_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>CIF</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.cif_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocGeneral('cif')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="CIF"
                  v-model="formDataDocGeneral.cif_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>

            <div class="mb-4">
              <template v-if="doc_general?.opinion_cumplimiento_32d_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>32-D</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.opinion_cumplimiento_32d_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocGeneral('32d')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="32-D"
                  v-model="formDataDocGeneral.opinion_cumplimiento_32d_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
            <div class="mb-4">
              <template v-if="doc_general?.comprobante_domicilio_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Comprobante de domicilio</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.comprobante_domicilio_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocGeneral('domicilio')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Comprobante de domicilio"
                  v-model="formDataDocGeneral.comprobante_domicilio_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
            <div class="mb-4">
              <template v-if="doc_general?.catalogo_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Lista de productos o servicios</span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.catalogo_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocGeneral('catalogo')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Lista de productos o servicios"
                  v-model="formDataDocGeneral.catalogo_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
            <div class="mb-4">
              <template v-if="doc_general?.ine_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>INE </span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.ine_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocGeneral('ine')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="INE"
                  v-model="formDataDocGeneral.ine_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
            <div class="mb-4">
              <template v-if="doc_general?.informacion_bancaria_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Información bancaria </span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.informacion_bancaria_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocGeneral('bancaria')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Información bancaria"
                  v-model="formDataDocGeneral.informacion_bancaria_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
            <div class="mb-4">
              <template v-if="doc_general?.opinion_cumplimiento_34_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>34D </span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.opinion_cumplimiento_34_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn @click="deleteDocGeneral('34d')" color="primary" x-small fab>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="34D"
                  v-model="formDataDocGeneral.opinion_cumplimiento_34_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
            <div class="mb-4">
              <template v-if="doc_general?.acta_constitutiva_adj">
                <v-card
                  class="d-flex align-center justify-space-between pa-3 mb-4"
                  outlined
                >
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-2">attachment</v-icon>
                    <span>Acta constitutiva </span>
                  </div>
                  <div>
                    <v-btn
                      :href="doc_general?.acta_constitutiva_adj"
                      target="_blank"
                      color="primary"
                      x-small
                      fab
                      class="mr-2"
                    >
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <v-btn
                      @click="deleteDocGeneral('constitutiva')"
                      color="primary"
                      x-small
                      fab
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </template>
              <template v-else>
                <v-file-input
                  label="Acta constitutiva"
                  v-model="formDataDocGeneral.acta_constitutiva_adj"
                  :rules="[rules.fileSize]"
                  accept=".pdf"
                  show-size
                  dense
                ></v-file-input>
              </template>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDocGeneral = false"
            >Cancelar</v-btn
          >
          <v-btn
            text
            color="blue darken-1"
            :loading="loading"
            :disabled="loading"
            @click="guardarDocGeneral"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEvidencia" max-width="800px">
      <v-card>
        <v-card-title class="headline primary">
          <v-icon left color="white">photo</v-icon>
          <span style="color: white; font-weight: bold">Evidencias fotograficas</span>
        </v-card-title>
        <v-card-text>
          <!-- Input de archivo y botón de agregar -->

          <br />
          <v-row>
            <v-col cols="10">
              <v-form ref="form_doc_evidencia" v-model="valid">
                <v-file-input
                  label="Foto de las instalaciones"
                  v-model="evidencia"
                  :rules="[rules.fileSize]"
                  accept="image/*"
                  show-size
                  dense
                ></v-file-input>
              </v-form>
            </v-col>
            <v-col cols="2">
              <v-btn
                class="mx-2"
                :loading="loading"
                :disabled="loading"
                @click="guardarEvidencia"
                color="primary"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>

          <!-- Alerta o carrusel -->
          <v-alert v-if="!evidencias.length" type="info" dense class="mt-3">
            No hay imágenes para mostrar.
          </v-alert>

          <v-carousel v-else cycle height="500px" class="mt-3">
            <v-carousel-item v-for="(image, index) in evidencias" :key="index">
              <v-btn
                class="mb-2"
                color="primary"
                block
                :loading="loading"
                :disabled="loading"
                @click="deleteEvidencia(image)"
              >
                Eliminar esta imagen
              </v-btn>
              <v-img :src="image.evidencia" contain></v-img>
              <!-- Botón para eliminar imagen -->
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveImages">Guardar</v-btn>
          <v-btn color="grey" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <div class="pb-10 d-flex align-center justify-space-between">
      <div>
        <h2>Prestadores de Servicios</h2>
        <span style="font-size: 13px; color: gray">
          Alta y gestion de prestadores de servicios.
        </span>
      </div>

      <v-btn v-if="usuario_log.rol !== 'Lectura'" @click="dialogAltaPsp = true"
        >Alta de PSP</v-btn
      >
    </div>
    <v-layout>
      <v-flex>
        <material-card icon="group" title="Altas de PSP creadas " class="px-5 py-3">
          <v-layout>
            <v-flex>
              <!-- <v-row>
                <v-col cols="12">
                  <div class="input-filter">
                    <v-menu offset-y :nudge-width="150">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                          <v-icon>filter_list</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item dense>
                          <v-list-item-title>
                            <span class="texto">Folio</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div class="text-center"></div>
                    <div v-if="arr.length == 0" class="filter-label">
                      <span>Ningun filtro seleccionado...</span>
                    </div>
                    <div v-else class="filter-chips">
                      <v-chip
                        class="ml-2"
                        v-for="(item, index) in arr"
                        :key="index"
                        close
                        close-icon="cancel"
                        @click:close="eliminarFiltro(index)"
                        color="primary"
                        small
                      >
                        {{ item.label }}
                      </v-chip>
                    </div>
                  </div>
                </v-col>
              </v-row> -->
            </v-flex>
          </v-layout>
          <v-data-table
            :headers="headers"
            :items="psp_lista"
            dense
            class="custom-table-header mt-3"
            item-value="id"
            hide-default-footer
          >
            <template v-slot:item.razon_social="{ item }">
              <td style="font-size: 12px; font-weight: bold">
                {{ item.razon_social }}
              </td>
            </template>
            <template v-slot:item.rfc="{ item }">
              <td style="font-size: 12px; text-transform: uppercase">
                {{ item.rfc }}
              </td>
            </template>
            <template v-slot:item.responsable="{ item }">
              <td style="font-size: 12px">
                {{ item.razon_social }}
              </td>
            </template>
            <template v-slot:item.correo_responsable="{ item }">
              <td style="font-size: 12px">
                {{ item.correo_responsable }}
              </td>
            </template>

            <template v-slot:item.fecha_creacion="{ item }">
              <td style="font-size: 12px">
                {{ item.createdAt | moment("dddd D MMM YY, h:mm a") }}
              </td>
            </template>

            <template v-slot:item.tipo_psp="{ item }">
              <td style="font-size: 12px">
                <v-chip
                  small
                  class="ma-2"
                  label
                  color="green"
                  dark
                  v-if="item.tipo_psp == 'Moral'"
                >
                  Persona Moral
                </v-chip>
                <v-chip
                  small
                  class="ma-2"
                  label
                  color="blue"
                  dark
                  v-if="item.tipo_psp == 'Fisica'"
                >
                  Persona Fisica
                </v-chip>
              </td>
            </template>
            <template v-slot:item.opciones="{ item }">
              <td class="text-right">
                <v-menu bottom left rounded="lg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>more_horiz</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="getDocumentGeneralPsp(item)" link>
                      <v-list-item-title>Documentación general</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="getDocumentFinPsp(item)" link>
                      <v-list-item-title> Documentación financiera</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="getDocumentTecPsp(item)" link>
                      <v-list-item-title
                        >Documentación tecnica y administrativa</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="getDocumentCartasPsp(item)" link>
                      <v-list-item-title>Cartas de compromiso</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="getEvidenciaPsp(item)" link>
                      <v-list-item-title>Album fotografico de oficinas</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </material-card>

        <template>
          <div class="text-center mt-3">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="7"
            ></v-pagination>
          </div>
        </template>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import MaterialCard from "../components/MaterialCard.vue";
import LoadingOverlay from "../components/Loading.vue";
import {
  guardarPsp,
  listarPsp,
  guardarDocFinPsp,
  listDocFinPsp,
  listDocTecPsp,
  guardarDocTecPsp,
  listDocCartasPsp,
  guardarDocCartasPsp,
  listDocGeneralPsp,
  guardarDocGeneralPsp,
  listEvidenciaPsp,
  guardarEvidenciaPsp,
  deleteEvidenciaPsp,
} from "../../api/endpoints";
import { uploadDocument, uploadDocumentPsp } from "../../helpers/handleUploadsDocument";
import moment from "moment";

export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  data() {
    return {
      arr: [],
      headers: [
        { text: "Razon social", value: "razon_social" },
        { text: "RFC", value: "rfc" },
        { text: "Responsable", value: "responsable" },
        { text: "Correo responsable", value: "correo_responsable" },
        { text: "Tipo de PSP", value: "tipo_psp" },
        { text: "Fecha de creación", value: "fecha_creacion" },
        { text: "Opciones", value: "opciones", align: "right" },
      ],
      totalPages: 0,
      valid: false,
      page: 1,
      usuario_log: [],
      psp_lista: [],
      loading: false,
      dialogAltaPsp: false,
      dialogDocFinanciero: false,
      dialogDocTecnica: false,
      dialogDocCartas: false,
      dialogDocGeneral: false,
      dialogEvidencia: false,
      valid_psp: false,
      pspSelected: [],
      doc_financiera: [],
      doc_cartas: [],
      doc_tecnica: [],
      doc_general: [],
      evidencias: [],
      evidencia: null,
      formData: {
        razon_social: "",
        rfc: "",
        responsable: "",
        correo_responsable: "",
        tipo_psp: "",
      },
      formDataDocFinanciera: {
        declaracion_anual_adj: null,
        estado_cuenta_adj: null,
        estado_financiero_adj: null,
      },
      formDataCartas: {
        anexo_a: null,
        anexo_b: null,
        anexo_c: null,
        anexo_d: null,
      },
      formDataDocTecnica: {
        relacion_equipo_adj: null,
        relacion_vehiculos_adj: null,
        relacion_personal_adj: null,
        cartas_recomendacion_adj: null,
        registro_imss_personal_temporales_adj: null,
        registro_imss_personal_adj: null,
      },
      formDataDocGeneral: {
        rfc_adj: null,
        curriculum_adj: null,
        cif_adj: null,
        opinion_cumplimiento_32d_adj: null,
        comprobante_domicilio_adj: null,
        catalogo_adj: null,
        ine_adj: null,
        informacion_bancaria_adj: null,
        opinion_cumplimiento_34_adj: null,
        acta_constitutiva_adj: null,
      },
      rules: {
        required: (value) => !!value || "Campo requerido",
        email: (value) => /.+@.+\..+/.test(value) || "Correo inválido",
        fileSize: (value) =>
          !value ||
          value.size <= 2 * 1024 * 1024 ||
          "El archivo debe pesar menos de 2 MB",
        unique: (value) =>
          // Esta regla debe validar en el backend para asegurar unicidad.
          true || "Este valor ya está registrado",
      },
    };
  },
  created() {
    this.getPsp();
    this.usuario_log = this.$store.state.usuario;
  },
  methods: {
    alerts(type) {
      switch (type) {
        case "error":
          this.$notify({
            title: "Error!",
            text: "Formulario incompleto o error en el servidor",
            type: "error",
          });
          break;
        case "success":
          this.$notify({
            title: "¡Éxito!",
            text: "La operación se completo con éxito",
            type: "success",
          });
          break;
      }
    },
    resetFormData() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
      if (this.$refs.form) this.$refs.form.resetValidation(); // Reinicia las validaciones
    },
    resetFormDataFinanc() {
      Object.keys(this.formDataDocFinanciera).forEach((key) => {
        this.formDataDocFinanciera[key] = null;
      });
      if (this.$refs.form_doc_financera) this.$refs.form_doc_financera.resetValidation(); // Reinicia las validaciones
    },
    async guardarPsp() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        await guardarPsp(this.formData)
          .then(() => {
            this.dialogAltaPsp = false;
            this.getPsp();
            this.alerts("success");
            this.resetFormData();
          })
          .catch((err) => {
            this.alerts("error");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alerts("error");
      }
    },

    async guardarEvidencia() {
      if (this.$refs.form_doc_evidencia.validate()) {
        this.loading = true;
        const data = {
          idPsp: this.pspSelected._id,
          tipo: "oficinas",
        };

        data.evidencia = await uploadDocumentPsp(this.evidencia);
        console.log(data);

        await guardarEvidenciaPsp(data)
          .then(() => {
            this.getEvidenciaPsp(this.pspSelected);
            this.alerts("success");
          })
          .catch((err) => {
            this.alerts("error");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alerts("error");
      }
    },

    async guardarDocFinanciera() {
      if (this.$refs.form_doc_financera.validate()) {
        this.loading = true;
        try {
          const datos = {
            declaracion_anual_adj: this.doc_financiera.declaracion_anual_adj || "",
            estado_cuenta_adj: this.doc_financiera.estado_cuenta_adj || "",
            estado_financiero_adj: this.doc_financiera.estado_financiero_adj || "",
          };

          const uploads = [
            {
              key: "declaracion_anual_adj",
              file: this.formDataDocFinanciera.declaracion_anual_adj,
            },
            {
              key: "estado_cuenta_adj",
              file: this.formDataDocFinanciera.estado_cuenta_adj,
            },
            {
              key: "estado_financiero_adj",
              file: this.formDataDocFinanciera.estado_financiero_adj,
            },
          ];

          for (const { key, file } of uploads) {
            if (file) {
              datos[key] = await uploadDocumentPsp(file);
            }
          }

          datos.idPsp = this.pspSelected._id;
          await guardarDocFinPsp(datos);
          this.getDocumentFinPsp(this.pspSelected);
          this.resetFormDataFinanc();
          this.alerts("success");
        } catch (error) {
          console.error(error);
          this.alerts("error");
        } finally {
          this.loading = false;
        }
      } else {
        this.alerts("error");
      }
    },

    async guardarDocTecnica() {
      if (this.$refs.form_doc_tecnica.validate()) {
        this.loading = true;
        try {
          const datos = {
            relacion_equipo_adj: this.doc_tecnica.relacion_equipo_adj || "",
            relacion_vehiculos_adj: this.doc_tecnica.relacion_vehiculos_adj || "",
            relacion_personal_adj: this.doc_tecnica.relacion_personal_adj || "",
            cartas_recomendacion_adj: this.doc_tecnica.cartas_recomendacion_adj || "",
            registro_imss_personal_temporales_adj:
              this.doc_tecnica.registro_imss_personal_temporales_adj || "",
            registro_imss_personal_adj: this.doc_tecnica.registro_imss_personal_adj || "",
          };

          const uploads = [
            {
              key: "relacion_equipo_adj",
              file: this.formDataDocTecnica.relacion_equipo_adj,
            },
            {
              key: "relacion_vehiculos_adj",
              file: this.formDataDocTecnica.relacion_vehiculos_adj,
            },
            {
              key: "relacion_personal_adj",
              file: this.formDataDocTecnica.relacion_personal_adj,
            },
            {
              key: "cartas_recomendacion_adj",
              file: this.formDataDocTecnica.cartas_recomendacion_adj,
            },
            {
              key: "registro_imss_personal_temporales_adj",
              file: this.formDataDocTecnica.registro_imss_personal_temporales_adj,
            },
            {
              key: "registro_imss_personal_adj",
              file: this.formDataDocTecnica.registro_imss_personal_adj,
            },
          ];

          for (const { key, file } of uploads) {
            if (file) {
              datos[key] = await uploadDocumentPsp(file);
            }
          }

          datos.idPsp = this.pspSelected._id;
          await guardarDocTecPsp(datos);
          this.getDocumentTecPsp(this.pspSelected);
          //this.resetFormDataFinanc();
          this.alerts("success");
        } catch (error) {
          console.error(error);
          this.alerts("error");
        } finally {
          this.loading = false;
        }
      } else {
        this.alerts("error");
      }
    },

    async guardarDocCartas() {
      if (this.$refs.form_doc_cartas.validate()) {
        this.loading = true;
        try {
          const datos = {
            anexo_a: this.doc_cartas.anexo_a || "",
            anexo_b: this.doc_cartas.anexo_b || "",
            anexo_c: this.doc_cartas.anexo_c || "",
            anexo_d: this.doc_cartas.anexo_d || "",
          };

          const uploads = [
            {
              key: "anexo_a",
              file: this.formDataCartas.anexo_a,
            },
            {
              key: "anexo_b",
              file: this.formDataCartas.anexo_b,
            },
            {
              key: "anexo_c",
              file: this.formDataCartas.anexo_c,
            },
            {
              key: "anexo_d",
              file: this.formDataCartas.anexo_d,
            },
          ];

          for (const { key, file } of uploads) {
            if (file) {
              datos[key] = await uploadDocumentPsp(file);
            }
          }

          datos.idPsp = this.pspSelected._id;
          console.log(datos);
          await guardarDocCartasPsp(datos);
          this.getDocumentCartasPsp(this.pspSelected);
          //  this.resetFormDataFinanc();
          this.alerts("success");
        } catch (error) {
          console.error(error);
          this.alerts("error");
        } finally {
          this.loading = false;
        }
      } else {
        this.alerts("error");
      }
    },

    async guardarDocGeneral() {
      if (this.$refs.form_doc_cartas.validate()) {
        this.loading = true;
        try {
          const datos = {
            rfc_adj: this.doc_general.rfc_adj || "",
            curriculum_adj: this.doc_general.curriculum_adj || "",
            cif_adj: this.doc_general.cif_adj || "",
            opinion_cumplimiento_32d_adj:
              this.doc_general.opinion_cumplimiento_32d_adj || "",

            comprobante_domicilio_adj: this.doc_general.comprobante_domicilio_adj || "",
            catalogo_adj: this.doc_general.catalogo_adj || "",
            ine_adj: this.doc_general.ine_adj || "",
            informacion_bancaria_adj: this.doc_general.informacion_bancaria_adj || "",

            opinion_cumplimiento_34_adj:
              this.doc_general.opinion_cumplimiento_34_adj || "",
            acta_constitutiva_adj: this.doc_general.acta_constitutiva_adj || "",
          };

          const uploads = [
            {
              key: "rfc_adj",
              file: this.formDataDocGeneral.rfc_adj,
            },
            {
              key: "curriculum_adj",
              file: this.formDataDocGeneral.curriculum_adj,
            },
            {
              key: "cif_adj",
              file: this.formDataDocGeneral.cif_adj,
            },
            {
              key: "opinion_cumplimiento_32d_adj",
              file: this.formDataDocGeneral.opinion_cumplimiento_32d_adj,
            },

            {
              key: "comprobante_domicilio_adj",
              file: this.formDataDocGeneral.comprobante_domicilio_adj,
            },
            {
              key: "catalogo_adj",
              file: this.formDataDocGeneral.catalogo_adj,
            },
            {
              key: "ine_adj",
              file: this.formDataDocGeneral.ine_adj,
            },
            {
              key: "informacion_bancaria_adj",
              file: this.formDataDocGeneral.informacion_bancaria_adj,
            },
            {
              key: "opinion_cumplimiento_34_adj",
              file: this.formDataDocGeneral.opinion_cumplimiento_34_adj,
            },
            {
              key: "acta_constitutiva_adj",
              file: this.formDataDocGeneral.acta_constitutiva_adj,
            },
          ];

          for (const { key, file } of uploads) {
            if (file) {
              datos[key] = await uploadDocumentPsp(file);
            }
          }

          datos.idPsp = this.pspSelected._id;
          console.log(datos);
          await guardarDocGeneralPsp(datos);
          this.getDocumentGeneralPsp(this.pspSelected);
          //  this.resetFormDataFinanc();
          this.alerts("success");
        } catch (error) {
          console.error(error);
          this.alerts("error");
        } finally {
          this.loading = false;
        }
      } else {
        this.alerts("error");
      }
    },

    async getPsp() {
      this.loading = true;
      await listarPsp([], this.page)
        .then((e) => {
          this.psp_lista = e.data.reg;
          this.totalPages = e.data.totalPaginas;
          console.log(e.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarDialogAdj(item, dialog) {
      this.pspSelected = item;
      switch (dialog) {
        case "financiero":
          this.dialogDocFinanciero = true;
          break;
      }
    },

    async getDocumentFinPsp(item) {
      this.loading = true;
      this.pspSelected = item;

      await listDocFinPsp(item._id)
        .then((e) => {
          this.dialogDocFinanciero = true;
          if (e.data == null) {
            this.doc_financiera = {
              declaracion_anual_adj: "",
              estado_cuenta_adj: "",
              estado_financiero_adj: "",
            };
          } else {
            this.doc_financiera = e.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getDocumentTecPsp(item) {
      this.loading = true;
      this.pspSelected = item;

      await listDocTecPsp(item._id)
        .then((e) => {
          this.dialogDocTecnica = true;
          this.doc_tecnica = e.data;
          if (e.data == null) {
            this.doc_tecnica = {
              relacion_equipo_adj: "",
              relacion_vehiculos_adj: "",
              relacion_personal_adj: "",
              cartas_recomendacion_adj: "",
              registro_imss_personal_temporales_adj: "",
              registro_imss_personal_adj: "",
            };
          } else {
            this.doc_tecnica = e.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getDocumentCartasPsp(item) {
      this.loading = true;
      this.pspSelected = item;

      await listDocCartasPsp(item._id)
        .then((e) => {
          console.log(e.data);
          this.dialogDocCartas = true;
          if (e.data == null) {
            this.doc_cartas = {
              anexo_a: "",
              anexo_b: "",
              anexo_c: "",
              anexo_d: "",
            };
          } else {
            this.doc_cartas = e.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getDocumentGeneralPsp(item) {
      this.loading = true;
      this.pspSelected = item;

      await listDocGeneralPsp(item._id)
        .then((e) => {
          console.log(e.data);
          this.dialogDocGeneral = true;
          if (e.data == null) {
            this.doc_general = {
              rfc_adj: "",
              curriculum_adj: "",
              cif_adj: "",
              opinion_cumplimiento_32d_adj: "",

              comprobante_domicilio_adj: "",
              catalogo_adj: "",
              ine_adj: "",
              informacion_bancaria_adj: "",

              opinion_cumplimiento_34_adj: "",
              acta_constitutiva_adj: "",
            };
          } else {
            this.doc_general = e.data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getEvidenciaPsp(item) {
      this.loading = true;
      this.pspSelected = item;

      await listEvidenciaPsp(item._id)
        .then((e) => {
          this.dialogEvidencia = true;
          this.evidencias = e.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async deleteEvidencia(item) {
      this.loading = true;
      await deleteEvidenciaPsp(item._id)
        .then(() => {
          this.getEvidenciaPsp(this.pspSelected);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteDocFinanc(item) {
      switch (item) {
        case "declaracion":
          this.doc_financiera.declaracion_anual_adj = "";
          break;
        case "cuenta":
          this.doc_financiera.estado_cuenta_adj = "";
          break;
        case "financiero":
          this.doc_financiera.estado_financiero_adj = "";
          break;
      }
    },

    deleteDocCartas(item) {
      switch (item) {
        case "a":
          this.doc_cartas.anexo_a = "";
          break;
        case "b":
          this.doc_cartas.anexo_b = "";
          break;
        case "c":
          this.doc_cartas.anexo_c = "";
          break;
        case "d":
          this.doc_cartas.anexo_d = "";
          break;
      }
    },

    deleteDocTec(item) {
      switch (item) {
        case "equipos":
          this.doc_tecnica.relacion_equipo_adj = "";
          break;
        case "vehiculos":
          this.doc_tecnica.relacion_vehiculos_adj = "";
          break;
        case "personal":
          this.doc_tecnica.relacion_personal_adj = "";
          break;
        case "cartas":
          this.doc_tecnica.cartas_recomendacion_adj = "";
          break;
        case "obrero_trabadores":
          this.doc_tecnica.registro_imss_personal_adj = "";
          break;
        case "obrero_trabadores_temporal":
          this.doc_tecnica.registro_imss_personal_temporales_adj = "";
          break;
      }
    },

    deleteDocGeneral(item) {
      switch (item) {
        case "rfc":
          this.doc_general.rfc_adj = "";
          break;
        case "curriculum":
          this.doc_general.curriculum_adj = "";
          break;
        case "cif":
          this.doc_general.cif_adj = "";
          break;
        case "32d":
          this.doc_general.opinion_cumplimiento_32d_adj = "";
          break;
        case "domicilio":
          this.doc_general.comprobante_domicilio_adj = "";
          break;
        case "catalogo":
          this.doc_general.catalogo_adj = "";
          break;
        case "ine":
          this.doc_general.ine_adj = "";
          break;
        case "34d":
          this.doc_general.opinion_cumplimiento_34_adj = "";
          break;
        case "constitutiva":
          this.doc_general.acta_constitutiva_adj = "";
          break;
        case "bancaria":
          this.doc_general.informacion_bancaria_adj = "";
          break;
      }
    },
  },
};
</script>

<style scoped></style>
