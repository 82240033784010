<template>
  <v-container class="text-center">
    <!-- Tarjeta que contiene la información de autorización -->
    <v-card class="pa-4" v-if="autorizado !== null">
      <!-- Animación de éxito centrada -->
      <v-card-title class="d-flex justify-center">
        <v-icon
          :color="autorizado ? 'green' : 'warning'"
          large
          class="success-animation success-icon"
          >{{ autorizado ? "check" : "warning" }}</v-icon
        >
      </v-card-title>

      <v-card-subtitle class="text-center">
        La solicitud de acceso a PSP con folio:
        <strong>AP-{{ folio }}</strong>
        {{ autorizado ? "ha sido autorizado con éxito." : "ya se encuentra autorizada" }}
      </v-card-subtitle>

      <v-card-text>
        <!-- Información adicional: Folio, Persona, Fecha -->
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Folio:</v-list-item-title>
              <v-list-item-subtitle>{{ folio }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Autorizado por:</v-list-item-title>
              <v-list-item-subtitle>{{ authorizedBy }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Fecha de Autorización:</v-list-item-title>
              <v-list-item-subtitle>{{ authorizationDate }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions class="d-flex justify-center">
        <v-btn color="success" @click="goBack">Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      autorizado: null,
      loading: false,
      itemId: this.$route.params._id, // Obtener el parámetro id desde la ruta
      folio: "", // Simulación del folio
      authorizedBy: "", // Simulación del nombre de la persona que autorizó
      authorizationDate: new Date(),
    };
  },
  created() {},

  methods: {
    goBack() {
      this.$router.push({ name: "Home" }); // Redirige al usuario a la página principal
    },

    activar() {
      this.loading = true;

      const configuracion = {
        headers: {
          Authorization: `Bearer ` + this.$store.state.token,
        },
      };
      axios
        .put(
          "psp/aut3",
          {
            _id: this.itemId,
          },
          configuracion
        )

        .then((response) => {
          this.folio = response.data.folio; // Ahora 'this' se refiere al componente Vue
          this.authorizedBy = response.data.nombre_completo;
          this.authorizationDate = response.data.fecha_aprobacion;
          this.autorizado = response.data.autorizado;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.activar();
  },
};
</script>

<style scoped>
/* Animación para el ícono de éxito */
.success-animation {
  animation: successAnimation 1.5s ease-in-out forwards;
}

/* Animación de crecimiento y escala */
@keyframes successAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  50% {
    opacity: 1;
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

/* Estilo del ícono de éxito */
</style>
