<template>
  <div>
    <loading-overlay :overlay="loading"></loading-overlay>
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar" absolute bottom right timeout="3000" color="green">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="dark" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div class="pb-10 d-flex align-center justify-space-between">
      <div>
        <h2>Solicitudes Unicas de acceso</h2>
        <span style="font-size: 13px; color: gray">
          Solicita y gestiona pases de acceso al muelle para embarcaciones.
        </span>
      </div>

      <v-btn
        v-if="usuario_log.rol == 'Agencia' || usuario_log.rol == 'root'"
        @click="mostrarNuevo()"
        >Nueva Solicitud</v-btn
      >
    </div>

    <v-dialog v-model="adModal" max-width="290">
      <v-card>
        <v-card-title class="headline" v-if="adAccion == 1"> Activar Item </v-card-title>
        <v-card-title class="headline" v-if="adAccion == 2">
          Desactivar Item
        </v-card-title>
        <v-card-title class="headline" v-if="adAccion == 4">
          Finalizar Item
        </v-card-title>

        <v-card-text>
          Estas a punto de <span v-if="adAccion == 1">Aprobar</span>
          <span v-if="adAccion == 2">Cancelar</span>
          <span v-if="adAccion == 3">Aprobar</span>
          <span v-if="adAccion == 4">Finalizar</span
          ><span v-if="adAccion == 5">Finalizar</span> la solicitud con folio SU{{
            adNombre
          }}
        </v-card-text>
        <v-col cols="12" sm="12" md="12" v-if="adAccion == 2">
          <v-textarea
            name="input-7-1"
            label="Motivo de Cancelación"
            value=""
            v-model="motivo_cancelacion"
          ></v-textarea>
        </v-col>
        <v-col v-if="adAccion == 4" cols="12" sm="12" md="12">
          <v-text-field
            label="Atraque"
            type="datetime-local"
            v-model="atraqueReal"
          ></v-text-field>
          <v-text-field
            label="Zarpe"
            v-model="zarpeReal"
            type="datetime-local"
          ></v-text-field>
          <v-text-field
            v-model="cargaReal"
            label="Carga de materiales"
            type="number"
            suffix="Ton"
          ></v-text-field>
          <v-text-field
            v-model="descargaReal"
            label="Descarga de materiales"
            type="number"
            suffix="Ton"
          ></v-text-field>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="activarDesactivarCerrar()" small color="orange"> Salir </v-btn>
          <v-btn v-if="adAccion == 1" @click="activar()" small color="primary">
            Aprobar Solicitud
          </v-btn>

          <v-btn v-if="adAccion == 3" @click="aprobarApi()" small color="primary">
            Aprobar Solicitud
          </v-btn>
          <v-btn v-if="adAccion == 2" @click="desactivar()" small color="error">
            Cancelar Solicitud
          </v-btn>
          <v-btn v-if="adAccion == 4" @click="finalizarServicio()" small color="success">
            Finalizar Solicitud
          </v-btn>
          <v-btn
            v-if="adAccion == 5"
            @click="finalizarServicioNoRealizado()"
            small
            color="success"
          >
            Finalizar Solicitud
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogReprogramacion"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-toolbar color="primary" dark> <h3>Reprogramar solicitud</h3></v-toolbar>
      <v-card>
        <v-card-text class="pa-7">
          <span class=""
            >Al actualizar la ETA y ETD del registro, las aprobaciones se retirarán, y
            será necesario esperar una nueva aprobación de la solicitud para poder
            continuar con el ingreso.</span
          >

          <div class="pa-5">
            <v-row dense>
              <v-col sm="12" lg="12">
                <v-text-field
                  v-model="eta_reprog"
                  type="datetime-local"
                  label="ETA"
                ></v-text-field>
              </v-col>
              <v-col sm="12" lg="12">
                <v-text-field
                  type="datetime-local"
                  v-model="etd_reprog"
                  label="E.T.D"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :loading="loading"
            color="primary"
            @click="reprogramarSolicitudUpdate()"
            block
          >
            Guardar cambios
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAut1" max-width="500px">
      <v-card>
        <v-toolbar color="primary" dark>Autorizar solicitud de acceso</v-toolbar>

        <v-container>
          <v-row v-if="itemSelected.embarcacion">
            <v-col cols="12">
              <p style="font-size: 14px; color: gray">
                Se ha recibido la solicitud
                <strong>#{{ itemSelected.folio_solicitud }}</strong> para que la
                embarcación <strong>{{ itemSelected.embarcacion.nombre }}</strong
                >, procedente de <strong>{{ itemSelected.procedencia }}</strong
                >, acceda al muelle <strong>{{ itemSelected.muelle }}</strong
                >. Por favor, confirme si autoriza este acceso.
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAut1 = false">Salir</v-btn>
          <v-btn color="blue darken-1" :loading="loading" text @click="activar"
            >Autorizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAut2" max-width="500px">
      <v-card>
        <v-toolbar color="primary" dark>Autorizar solicitud de acceso</v-toolbar>

        <v-container>
          <v-row v-if="itemSelected.embarcacion">
            <v-col cols="12">
              <p style="font-size: 14px; color: gray">
                Se ha recibido la solicitud
                <strong>#{{ itemSelected.folio_solicitud }}</strong> para que la
                embarcación <strong>{{ itemSelected.embarcacion.nombre }}</strong
                >, procedente de <strong>{{ itemSelected.procedencia }}</strong
                >, acceda al muelle <strong>{{ itemSelected.muelle }}</strong
                >. Por favor, confirme si autoriza este acceso.
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAut2 = false">Salir</v-btn>
          <v-btn color="blue darken-1" :loading="loading" text @click="autorizarOpib"
            >Autorizar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on }"> </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row> </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text @click="guardar">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNuevaSolicitud"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-toolbar color="primary" dark>{{
          editedIndex
            ? "Editar solicitud Unica de Acceso"
            : "Guardar solicitud unica de acceso"
        }}</v-toolbar>
        <v-card-text>
          <div class="pa-8">
            <v-stepper v-model="e1">
              <loading-overlay :overlay="loading"></loading-overlay>

              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1">
                  Buque y estadia
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  infraestructura Portuaria
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">
                  Servicios Portuarios
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 4" step="4"> Carga </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step step="5"> Detalles </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-row dense>
                    <!-- Embarcación -->
                    <v-col cols="6" class="pb-4">
                      <span>Embarcación</span>
                      <v-autocomplete
                        v-model="embarcacion"
                        :items="embarcaciones"
                        hide-details
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <!-- Capitán -->
                    <v-col cols="6" class="pb-4">
                      <span>Capitán</span>
                      <v-text-field
                        v-model="capitan"
                        hide-details
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </v-col>

                    <!-- No. de Tripulantes -->

                    <!-- Procedencia -->
                    <v-col cols="6" class="pb-4">
                      <span>Procedencia</span>
                      <v-autocomplete
                        v-model="procedencia"
                        :items="procedencias"
                        hide-details
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <!-- Muelle de Arribo -->
                    <v-col cols="6" class="pb-4">
                      <span>Muelle de Arribo</span>
                      <v-autocomplete
                        v-model="muelle"
                        :items="muelles"
                        hide-details
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>

                    <!-- E.T.A. -->
                    <v-col cols="6" class="pb-4">
                      <span>E.T.A.</span>
                      <v-text-field
                        v-model="eta"
                        hide-details
                        dense
                        outlined
                        type="datetime-local"
                      ></v-text-field>
                    </v-col>

                    <!-- E.T.D. -->
                    <v-col cols="6" class="pb-4">
                      <span>E.T.D.</span>
                      <v-text-field
                        v-model="etd"
                        hide-details
                        dense
                        outlined
                        type="datetime-local"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-4">
                      <span>No. de Tripulantes</span>
                      <v-text-field
                        v-model="no_tripulantes"
                        hide-details
                        dense
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-btn color="primary" @click="checkDataStepper(1)"> Continuar </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <v-subheader class="pa-0">Pto. Fijo</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="infraestructura"
                        value="Pto. Fijo"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Atraque</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="infraestructura"
                        value="Atraque"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-subheader class="pa-0"
                        >Cuota por arribo a embarcaciones menores</v-subheader
                      >
                    </v-col>

                    <v-col cols="2" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="infraestructura"
                        value="Cuota por arribo a embarcaciones menores"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Pto. Variable</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="infraestructura"
                        value="Pto. Variable"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Muellaje</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="infraestructura"
                        value="Muellaje"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-subheader class="pa-0"
                        >Embarque y desembarque de pasajeros</v-subheader
                      >
                    </v-col>

                    <v-col cols="2" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="infraestructura"
                        value="Embarque y desembarque de pasajeros"
                      ></v-checkbox>
                    </v-col>
                  </v-row>

                  <div class="my-5">
                    <v-btn color="primary" @click="e1 = 3"> Continuar </v-btn>

                    <v-btn @click="e1 = 1" text> Regresar </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-row no-gutters>
                    <v-col cols="3">
                      <v-subheader class="pa-0">Piloto</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Piloto"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Amarre de cabos</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Amarre de cabos"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-subheader class="pa-0">Lubricantes</v-subheader>
                    </v-col>

                    <v-col cols="2" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Lubricantes"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="3">
                      <v-subheader class="pa-0">Suministro de agua potable</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Suministro de agua potable"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Combustible</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Combustible"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-subheader class="pa-0">Almacenaje</v-subheader>
                    </v-col>

                    <v-col cols="2" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Almacenaje"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-subheader class="pa-0">Recolección de basura</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Recolección de basura"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Avituallamiento</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Avituallamiento"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3">
                      <v-subheader class="pa-0">Reparaciones a flote</v-subheader>
                    </v-col>

                    <v-col cols="2" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="servicios_port"
                        value="Reparaciones a flote"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="2">
                      <v-subheader class="pa-0">Otro</v-subheader>
                    </v-col>
                    <v-col cols="10" class="text--secondary">
                      <v-text-field
                        class="pa-0"
                        hide-details
                        v-model="otros_servicios"
                        clearable="clearable"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="my-5">
                    <v-btn color="primary" @click="e1 = 4"> Continuar </v-btn>

                    <v-btn @click="e1 = 2" text> Regresar </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-row no-gutters>
                    <v-col cols="2">
                      <v-subheader class="pa-0">Carga</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="operacion_carga"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Descarga</v-subheader>
                    </v-col>

                    <v-col cols="1" class="text--secondary">
                      <v-checkbox
                        class="pa-0"
                        hide-details
                        v-model="operacion_descarga"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="2">
                      <v-subheader class="pa-0">Total de toneladas</v-subheader>
                    </v-col>

                    <v-col cols="4" class="text--secondary">
                      <v-text-field
                        class="pa-0"
                        hide-details
                        v-model="total_toneladas"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2">
                      <v-subheader class="pa-0">Tipo de carga:</v-subheader>
                    </v-col>

                    <v-col cols="10" class="text--secondary">
                      <v-text-field
                        class="pa-0"
                        hide-details
                        v-model="tipo_carga"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="my-5">
                    <v-btn color="primary" @click="e1 = 5"> Continuar </v-btn>

                    <v-btn @click="e1 = 3" text> Regresar </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="5">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <br />
                      <v-textarea
                        v-model="comentarios"
                        clearable="clearable"
                        outlined
                        label="Programa de actividades del buque en el puerto"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        v-model="observaciones"
                        clearable="clearable"
                        outlined
                        label="Comentarios y observaciones generales"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-btn
                    class="ma-2"
                    @click.native="guardar()"
                    small
                    outlined
                    color="indigo"
                    >{{ editedIndex ? "Editar" : "Guardar" }}</v-btn
                  >

                  <v-btn @click="e1 = 4" text> Regresar </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
          <div class="text-center ma-2">
            <v-snackbar
              v-model="snackbar"
              absolute
              bottom
              right
              timeout="2000"
              color="red"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn color="dark" text v-bind="attrs" @click="snackbar = false">
                  Cerrar
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialogNuevaSolicitud = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout>
      <v-flex>
        <material-card
          icon="content_paste"
          title="Solicitudes creadas "
          class="px-5 py-3"
        >
          <v-layout>
            <v-flex>
              <v-row>
                <v-col cols="12">
                  <div class="input-filter">
                    <v-menu offset-y :nudge-width="150">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                          <v-icon>filter_list</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item dense @click="showFilterFolio = true">
                          <v-list-item-title>
                            <span class="texto">Folio</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click="showFilterEmbarcacion = true">
                          <v-list-item-title>
                            <span class="texto">Embarcación</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click="showFilterProcedencia = true">
                          <v-list-item-title>
                            <span class="texto">Procedencia</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click="showFilterMuelle = true">
                          <v-list-item-title>
                            <span class="texto">Muelle</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click="showFilterEstatus = true">
                          <v-list-item-title>
                            <span class="texto">Estatus</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click="showFilterFecha = true">
                          <v-list-item-title>
                            <span class="texto">ETA</span></v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div class="text-center"></div>
                    <div v-if="arr.length == 0" class="filter-label">
                      <span>Ningun filtro seleccionado...</span>
                    </div>
                    <div v-else class="filter-chips">
                      <v-chip
                        class="ml-2"
                        v-for="(item, index) in arr"
                        :key="index"
                        close
                        close-icon="cancel"
                        @click:close="eliminarFiltro(index)"
                        color="primary"
                        small
                      >
                        {{ item.label }}
                      </v-chip>
                    </div>
                    <v-dialog
                      v-model="showFilterFolio"
                      transition="dialog-bottom-transition"
                      max-width="350"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>Busqueda por Folio</v-toolbar>
                        <v-card-text class="mt-10">
                          <v-text-field
                            v-model="folio_filter"
                            outlined
                            prefix="SU-"
                            dense
                            label="Folio"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="filtroLabelPush('folio')">Aplicar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="showFilterEmbarcacion"
                      transition="dialog-bottom-transition"
                      max-width="350"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark
                          >Busqueda por Embarcación</v-toolbar
                        >
                        <v-card-text class="mt-10">
                          <v-autocomplete
                            return-object
                            v-model="embarcacion_filter"
                            outlined
                            :items="embarcaciones"
                            dense
                            label="Ingrese la embarcación"
                          ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="filtroLabelPush('embarcacion')"
                            >Aplicar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="showFilterProcedencia"
                      transition="dialog-bottom-transition"
                      max-width="350"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark
                          >Busqueda por Procedencia</v-toolbar
                        >
                        <v-card-text class="mt-10">
                          <v-autocomplete
                            label="Procedencia"
                            v-model="procedencia_filter"
                            :items="procedencias"
                            hide-details
                            dense
                            outlined
                          ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="filtroLabelPush('procedencia')"
                            >Aplicar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="showFilterMuelle"
                      transition="dialog-bottom-transition"
                      max-width="350"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>Busqueda por Muelle</v-toolbar>
                        <v-card-text class="mt-10">
                          <v-autocomplete
                            label="Muelle"
                            :items="muelles"
                            v-model="muelle_filter"
                            hide-details
                            dense
                            outlined
                          ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="filtroLabelPush('muelle')">Aplicar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="showFilterEstatus"
                      transition="dialog-bottom-transition"
                      max-width="350"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>Busqueda por Estatus</v-toolbar>
                        <v-card-text class="mt-10">
                          <v-autocomplete
                            v-if="usuario_log.rol == 'Agencia'"
                            label="Estatus"
                            return-object
                            :items="[
                              { text: 'En proceso', value: '0' },
                              { text: 'Aprobado', value: '2' },
                            ]"
                            hide-details
                            dense
                            v-model="estatus_filter"
                            outlined
                          ></v-autocomplete>
                          <v-autocomplete
                            v-else
                            label="Estatus"
                            return-object
                            :items="[
                              { text: 'En proceso', value: '0' },
                              { text: 'Aut-1', value: '1' },
                              { text: 'Aprobado', value: '2' },
                            ]"
                            hide-details
                            dense
                            v-model="estatus_filter"
                            outlined
                          ></v-autocomplete>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="filtroLabelPush('estado')">Aplicar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog
                      v-model="showFilterFecha"
                      transition="dialog-bottom-transition"
                      max-width="350"
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>Busqueda por ETA</v-toolbar>
                        <v-card-text class="mt-10">
                          <v-text-field
                            v-model="eta_inicio_filter"
                            type="date"
                            outlined
                            dense
                            label="Inicio"
                          ></v-text-field>
                          <v-text-field
                            v-model="eta_fin_filter"
                            type="date"
                            outlined
                            dense
                            label="Fin"
                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn text @click="filtroLabelPush('eta')">Aplicar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
          <v-data-table
            :headers="headers"
            :items="solicitudUnica"
            dense
            class="custom-table-header mt-3"
            item-value="id"
            hide-default-footer
          >
            <template v-slot:item.folio_solicitud="{ item }">
              <td style="font-size: 12px; font-weight: bold">
                SU-{{ item.folio_solicitud }}
              </td>
            </template>

            <template v-slot:item.embarcacion.nombre="{ item }">
              <td style="font-size: 12px; color: gray; font-weight: bold">
                {{ item.embarcacion.nombre }}
              </td>
            </template>

            <template v-slot:item.procedencia="{ item }">
              <td style="font-size: 12px; color: gray; font-weight: bold">
                {{ item.procedencia }}
              </td>
            </template>

            <template v-slot:item.eta="{ item }">
              <td style="font-size: 12px; color: gray; font-weight: bold">
                {{ item.eta | moment("dddd Do MMMM YYYY, h:mm:ss a") }}
              </td>
            </template>

            <template v-slot:item.muelle="{ item }">
              <td style="font-size: 12px; color: gray; font-weight: bold">
                {{ item.muelle }}
              </td>
            </template>

            <template v-slot:item.estado="{ item }">
              <td>
                <v-chip
                  small
                  class="ma-2"
                  label
                  outlined
                  color="orange"
                  v-if="
                    item.estado == 0 || (item.estado == 1 && usuario_log.rol == 'Agencia')
                  "
                >
                  <v-avatar left>
                    <v-icon small>restore</v-icon>
                  </v-avatar>
                  En proceso de autorización
                </v-chip>

                <v-chip
                  small
                  class="ma-2"
                  label
                  outlined
                  color=""
                  v-if="
                    item.estado == 1 &&
                    (usuario_log.rol == 'Administrador' ||
                      usuario_log.rol == 'Operaciones' ||
                      usuario_log.rol == 'OPIP' ||
                      usuario_log.rol == 'root' ||
                      usuario_log.rol == 'Lectura')
                  "
                >
                  <v-avatar left>
                    <v-icon small>restore</v-icon>
                  </v-avatar>
                  Autorizado por el OPIP
                </v-chip>

                <v-chip
                  small
                  class="ma-2"
                  color="green"
                  label
                  outlined
                  v-if="item.estado == 2"
                >
                  <v-avatar left>
                    <v-icon small>done</v-icon>
                  </v-avatar>
                  Aprobado
                </v-chip>

                <v-chip
                  small
                  class="ma-2"
                  color="black"
                  text-color="white"
                  v-if="item.estado == 4"
                >
                  <v-avatar left>
                    <v-icon small>verified</v-icon>
                  </v-avatar>
                  Servicio Realizado
                </v-chip>

                <v-chip
                  small
                  class="ma-2"
                  color="black"
                  text-color="white"
                  v-if="item.estado == 5"
                >
                  <v-avatar left>
                    <v-icon small>auto_delete</v-icon>
                  </v-avatar>
                  Servicio No Realizado
                </v-chip>
              </td>
            </template>

            <template v-slot:item.opciones="{ item }">
              <td class="text-right">
                <v-menu bottom left rounded="lg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>more_horiz</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-if="
                        (item.estado !== '2' && usuario_log.rol == 'root') ||
                        usuario_log.rol == 'Agencia'
                      "
                      @click="editItem(item)"
                      link
                    >
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>

                    <!-- <v-list-item @click="mostrarComprobante(item)" link>
                      <v-list-item-title>Imprimir documento</v-list-item-title>
                    </v-list-item> -->

                    <v-list-item @click="generarPdfBackend(item)" link>
                      <v-list-item-title>Imprimir documento</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="usuario_log.rol == 'OPIP' && item.estado == '0'"
                      @click="mostrarDialogAut1(item)"
                      link
                    >
                      <v-list-item-title>Aprobar solicitud</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="usuario_log.rol == 'Operaciones' && item.estado == '1'"
                      @click="mostrarDialogAut2(item)"
                      link
                    >
                      <v-list-item-title>Aprobar solicitud</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="item.estado == '2'"
                      @click="imprimirPaseAcceso(item)"
                      link
                    >
                      <v-list-item-title>Imprimir pase de acceso</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="usuario_log.rol == 'Agencia'"
                      @click="mostrarDialogReprogramar(item)"
                      link
                    >
                      <v-list-item-title>Reprogramar solicitud</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </template>
          </v-data-table>
        </material-card>

        <template>
          <div class="text-center mt-3">
            <v-pagination
              v-model="page"
              @input="listar()"
              :length="totalPages"
              :total-visible="7"
            ></v-pagination>
          </div>
        </template>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import "font-awesome/css/font-awesome.min.css"; // Ensure you are using css-loader
import DatetimePicker from "vuetify-datetime-picker";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import emailjs from "emailjs-com";
import swal from "sweetalert";
import moment from "moment";
import { required, email, minLength } from "vuelidate/lib/validators";
import LoadingOverlay from "../components/Loading.vue";
import MaterialCard from "../components/MaterialCard.vue";
import store from "../../store";
import { generateAcces } from "./pdf/pase_acceso";
import { generarPdfSU, reprogramarSolicitud } from "../../api/endpoints";
import { imgEncabezado2 } from "../../helpers/imgBase64.js";
import QRCode from "qrcode";
import { apitab } from "../../helpers/logoBase64.js";

export default {
  components: {
    LoadingOverlay,
    MaterialCard,
  },
  icons: {
    iconfont: "fa4",
  },
  validations: {
    capitan: {
      required,
    },
    embarcacion: {
      required,
    },
    no_tripulantes: {
      required,
    },
    procedencia: {
      required,
    },
    muelle: {
      required,
    },

    comentarios: {
      required,
    },
    observaciones: {
      required,
    },
    eta: {
      required,
    },
    etd: {
      required,
    },
  },
  data() {
    return {
      //FILTROS
      showFilterFolio: false,
      showFilterFecha: false,
      showFilterEmbarcacion: false,
      showFilterProcedencia: false,
      showFilterMuelle: false,
      showFilterEstatus: false,
      folio_filter: "",
      embarcacion_filter: null,
      procedencia_filter: "",
      muelle_filter: "",
      estatus_filter: null,
      eta_inicio_filter: "",
      eta_fin_filter: "",

      arr: [],

      dialog: false,
      loading: false,
      dialogReprogramacion: false,
      eta_reprog: "",
      etd_reprog: "",
      search: "",
      e1: 1,
      dialogAut1: false,
      dialogAut2: false,
      itemSelected: [],
      solicitudUnica: [],
      totalPages: 0,
      page: 1,
      headers: [
        { text: "Folio", value: "folio_solicitud" },
        { text: "Embarcación", value: "embarcacion.nombre" },
        { text: "Procedencia", value: "procedencia" },
        { text: "ETA", value: "eta" },
        { text: "Muelle", value: "muelle" },
        { text: "Estatus", value: "estado" },
        { text: "Opciones", value: "opciones", align: "right" },
      ],
      editedIndex: true,
      _id: "",
      equis: "",
      panel: [0, 1, 2, 3, 4, 5],
      readonly: false,
      embarcacion: "",
      embarcaciones: [],
      procedencias: [
        "CARMEN",
        "DOS BOCAS",
        "FRONTERA",
        "TUXPAN",
        "SEYBAPLAYA",
        "AREA DE PLATAFORMAS",
      ],
      nombreCompleto: "",
      agencias: [],
      dialogs: false,
      nombre: "",
      usuario_log: "",
      fecha_solicitud: "",
      dialogNuevaSolicitud: false,
      usuarios: "",
      submited: false,
      total_toneladas: "",
      imgData: "",
      tipo_Carga: "",
      otroServicio: "",
      dates: ["2019-09-10", "2019-09-20"],
      agencia2: "",
      representante: "",
      muelles: ["FRONTERA", "CHILTEPEC", "SANCHEZ MAGALLANES"],
      muelle: "",
      pasajeros: [],
      verNuevo: 0,
      alert: "",
      snackbar: false,
      text: `Hello, I'm a snackbar`,
      checka: "",
      checkb: "",
      checkc: "",
      checkd: "",
      checke: "",
      checkf: "",
      checkg: "",
      checkh: "",
      checki: "",

      check1: "",
      check2: "",
      check3: "",
      check4: "",
      check5: "",
      check6: "",

      atraqueReal: "",
      zarpeReal: "",
      cargaReal: "",
      descargaReal: "",

      eta: "",
      folioId: "",
      procedencia: "",
      fechaSolicitada: "",
      etaReporte: "",
      carga_descarga: "",
      operacion: ["Carga", "Descarga", "NA"],
      operacion_carga: false,
      operacion_descarga: false,
      tipo_carga: "",
      etdReporte: "",
      etd: "",
      detalles: "",
      date: "",
      no_tripulantes: "",
      capitan: "",
      usuario: "",
      procedencia_solicitud: "",
      solicitud: "",
      nombreAgencia: "",
      nombreEmbarcacion: "",
      embarcacionQuery: "",
      agencia: "",
      servicios_port: [],
      estadia: "",
      aprobacionAmls: "",
      aprobacionApi: "",
      infraestructura: [],
      folioOtraSolicitud: "",
      otros_servicios: "",
      fecha: "",
      estadia: "",
      fecha_real: "",
      observaciones: "",
      folio: "",
      folioId: "",
      estado: "",
      embarcacionesQuery: [],
      comentarios: "",
      tipoServicio: [],
      comprobanteModal: 0,
      agenciasUsuario: [],
      etdispurge: "",
      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",
      company_logo: {
        w: 460,
        h: 60,
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex ? "Editar Solicitud" : "Nueva Solicitud";
    },

    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
    this.selectEmbarcaciones();
    this.emailIn();

    this.usuario_log = this.$store.state.usuario;
    this.listarAgencia(this.$store.state.usuario.agencia);
  },

  methods: {
    async generarPDF() {
      var doc = new jsPDF("p", "pt", "a4");
      var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var startX = 40;
      var startY = 20;

      //QR

      const qrText = this._id;
      const qrCodeDataURL = await QRCode.toDataURL(qrText, { width: 228 });

      if (qrCodeDataURL) {
        const qrWidth = 60; // Ancho del QR
        const qrHeight = 60; // Altura del QR
        const qrXPosition = 510; // Posiciona el QR en el centro horizontal
        const qrYPosition = 30; // Posición vertical más baja

        doc.addImage(qrCodeDataURL, "PNG", qrXPosition, qrYPosition, qrWidth, qrHeight);
      } else {
        console.error("Error al generar el código QR");
      }

      //Encabezado

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");

      doc.text("Solicitud Unica de Acceso", pageWidth / 2, startY + 90, {
        align: "center",
      });
      doc.addImage(
        imgEncabezado2,
        "JPEG",
        startX,
        (startY += 10),
        this.company_logo.w,
        this.company_logo.h
      );

      doc.addImage(apitab, "JPEG", startX + 380, (startY += 8), 80, 50);

      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");

      //Datos de Agencia
      doc.text("Agencia Consignataria", 40, 160);
      doc.line(170, 163, 530, 163);
      doc.text("Direccion", 40, 175);
      doc.line(170, 178, 530, 178);
      doc.text("RFC", 40, 190);
      doc.line(170, 193, 530, 193);
      doc.text("Email", 40, 205);
      doc.line(170, 208, 530, 208);
      doc.text(this.agenciasUsuario.nombre, 180, 160);
      doc.text(this.agenciasUsuario.direccion, 180, 175);
      doc.text(this.agenciasUsuario.rfc, 180, 190);
      doc.text(this.agenciasUsuario.email, 180, 205);

      doc.text(
        "Como representante de la embarcación abajo mencionada, cuyas características detallo, solicito a Usted Autorización para el atraque y uso",
        40,
        232,
        { align: "justify" }
      );
      doc.text(
        "de infraestructura del Recinto Portuario, comprometiendome a cumplir con las reglas de operación del puerto y cualquier otro lineamiento",
        40,
        244,
        { align: "justify" }
      );
      doc.text(
        "en materia de seguridad, protección y ambiental, así como a resarcir cualquier daño que se pueda ocasionar en la infraestructura portuaria.",
        40,
        256,
        { align: "justify" }
      );

      //Datos del Buque y Estadia
      doc.setFillColor(196, 196, 196);
      doc.rect(40, 270, 495, 10, "F");
      doc.text("Datos del buque y estadía", 42, 278);
      doc.text("Fecha y Hora de Solicitud", 42, 300);
      doc.text(this.fecha_solicitud, 173, 300);
      doc.line(170, 303, 290, 303);
      doc.text("Nombre del Buque", 42, 315);
      doc.text(this.embarcacion.nombre, 173, 315);
      doc.line(170, 318, 290, 318);
      doc.text("Capitan", 42, 330);
      doc.text(this.capitan, 173, 330);
      doc.line(170, 333, 290, 333);
      doc.text("Armador", 42, 345);
      doc.text(this.embarcacion.armador, 173, 345);
      doc.line(170, 348, 290, 348);
      doc.text("No. de Tripulantes", 42, 360);
      doc.text(this.no_tripulantes.toString(), 173, 360);
      doc.line(170, 363, 290, 363);
      doc.text("Procedencia", 42, 375);
      doc.text(this.procedencia_solicitud, 173, 375);
      doc.line(170, 378, 290, 378);

      doc.text("Tipo de Trafico", 300, 300);
      doc.text("Cabotaje", 413, 300);
      doc.line(410, 303, 530, 303);
      doc.text("Muelle de Arribo", 300, 315);
      doc.text(this.muelle, 413, 315);
      doc.line(410, 318, 530, 318);
      doc.text("Terminal de Arribo", 300, 330);
      doc.text("APITAB", 413, 330);
      doc.line(410, 333, 530, 333);
      doc.text("E.T.A.", 300, 345);
      doc.text(this.etaReporte, 413, 345);
      doc.line(410, 348, 530, 348);
      doc.text("E.T.D.", 300, 360);
      doc.text(this.etdReporte, 413, 360);
      doc.line(410, 363, 530, 363);
      doc.text("Estadia", 300, 375);
      doc.text(this.estadia, 413, 375);
      doc.line(410, 378, 530, 378);

      //Caracteristicas de la embarcacion

      doc.setFillColor(196, 196, 196);
      doc.rect(40, 393, 495, 10, "F");
      // doc.setFontType('bold')
      doc.text("Caracteristicas de la Embarcación", 42, 400);

      //  doc.setFontType('normal')

      doc.text("Bandera", 42, 420);
      doc.text(this.embarcacion.bandera, 113, 420);
      doc.line(110, 423, 190, 423);
      doc.text("Pto Matricula", 42, 435);
      doc.text(this.embarcacion.pto_matricula, 113, 435);
      doc.line(110, 438, 190, 438);
      doc.text("No. Matricula", 42, 450);
      doc.text(this.embarcacion.matricula, 113, 450);
      doc.line(110, 453, 190, 453);

      doc.text("T.R.B.", 195, 420);
      doc.text(this.embarcacion.tbr.toString(), 233, 420);
      doc.line(230, 423, 310, 423);
      doc.text("T.R.N,", 195, 435);
      doc.text(this.embarcacion.trn.toString(), 233, 435);
      doc.line(230, 438, 310, 438);
      doc.text("Clase", 195, 450);
      doc.text(this.embarcacion.tipo, 233, 450);
      doc.line(230, 453, 310, 453);

      doc.text("Eslora", 315, 420);
      doc.text(this.embarcacion.eslora.toString(), 353, 420);
      doc.line(350, 423, 410, 423);
      doc.text("Puntal", 315, 435);
      doc.text(this.embarcacion.puntal.toString(), 353, 435);
      doc.line(350, 438, 410, 438);
      doc.text("Calado", 315, 450);
      doc.text(this.embarcacion.calado.toString(), 353, 450);
      doc.line(350, 453, 410, 453);

      doc.text("Manga", 420, 420);
      doc.text(this.embarcacion.manga.toString(), 458, 420);
      doc.line(455, 423, 535, 423);
      doc.text("IMO", 420, 435);
      doc.text(this.embarcacion.imo.toString(), 458, 435);
      doc.line(455, 438, 535, 438);

      //Servicios Portuarios
      doc.setFillColor(196, 196, 196);
      doc.rect(40, 463, 495, 10, "F");
      //doc.setFontType('bold')
      doc.text("Servicios Portuarios Requeridos", 42, 470);
      // doc.setFontType('normal')
      doc.text("Pto Fijo", 42, 495);
      if (this.check1 == "jj") {
        doc.rect(110, 488, 10, 10, "F");
      } else {
        doc.rect(110, 488, 10, 10);
      }
      doc.text("Atraque", 140, 495);
      if (this.check2 == "jj") {
        doc.rect(195, 488, 10, 10, "F");
      } else {
        doc.rect(195, 488, 10, 10);
      }
      doc.text("Cuota por arribo a embarcaciones menores", 240, 495);
      if (this.check2 == "jj") {
        doc.rect(415, 488, 10, 10, "F");
      } else {
        doc.rect(415, 488, 10, 10);
      }

      doc.text("Pto Variable", 42, 510);
      if (this.check4 == "jj") {
        doc.rect(110, 503, 10, 10, "F");
      } else {
        doc.rect(110, 503, 10, 10);
      }
      doc.text("Muellaje", 140, 510);
      if (this.check5 == "jj") {
        doc.rect(195, 503, 10, 10, "F");
      } else {
        doc.rect(195, 503, 10, 10);
      }
      doc.text("Embarque y desembarque de pasajeros", 240, 510);
      if (this.check6 == "jj") {
        doc.rect(415, 503, 10, 10, "F");
      } else {
        doc.rect(415, 503, 10, 10);
      }

      //Programa de Actividades en el puerto
      doc.setFillColor(196, 196, 196);
      doc.rect(40, 523, 495, 10, "F");
      // doc.setFontType('bold')
      doc.text("Programa de actividades del buque en el puerto", 42, 530);
      //  doc.setFontType('normal')
      doc.rect(40, 533, 495, 30);
      doc.text(this.comentarios, 42, 543);

      //Servicios Portuarios Requeridos
      doc.setFillColor(196, 196, 196);
      doc.rect(40, 578, 495, 10, "F");
      //  doc.setFontType('bold')
      doc.text("Servicios portuarios requeridos", 42, 585);
      //  doc.setFontType('normal')

      doc.text("Piloto", 42, 600);
      if (this.checka == "jj") {
        doc.rect(190, 593, 10, 10, "F");
      } else {
        doc.rect(190, 593, 10, 10);
      }
      doc.text("Amarre de cabos", 230, 600);
      if (this.checkb == "jj") {
        doc.rect(325, 593, 10, 10, "F");
      } else {
        doc.rect(325, 593, 10, 10);
      }
      doc.text("Lubricantes", 375, 600);
      if (this.checkc == "jj") {
        doc.rect(470, 593, 10, 10, "F");
      } else {
        doc.rect(470, 593, 10, 10);
      }

      doc.text("Suministro de Agua Potable", 42, 615);
      if (this.checkd == "jj") {
        doc.rect(190, 608, 10, 10, "F");
      } else {
        doc.rect(190, 608, 10, 10);
      }
      doc.text("Combustible", 230, 615);
      if (this.checke == "jj") {
        doc.rect(325, 608, 10, 10, "F");
      } else {
        doc.rect(325, 608, 10, 10);
      }
      doc.text("Almacenaje", 375, 615);
      if (this.checkf == "jj") {
        doc.rect(470, 608, 10, 10, "F");
      } else {
        doc.rect(470, 608, 10, 10);
      }

      doc.text("Recolección de basura", 42, 630);
      if (this.checkg == "jj") {
        doc.rect(190, 623, 10, 10, "F");
      } else {
        doc.rect(190, 623, 10, 10);
      }
      doc.text("Avituallamiento", 230, 630);
      if (this.checkh == "jj") {
        doc.rect(325, 623, 10, 10, "F");
      } else {
        doc.rect(325, 623, 10, 10);
      }
      doc.text("Reparación a flote", 375, 630);
      if (this.checki == "jj") {
        doc.rect(470, 623, 10, 10, "F");
      } else {
        doc.rect(470, 623, 10, 10);
      }
      doc.text("Otro", 42, 645);
      doc.line(190, 648, 535, 648);
      doc.text(this.otros_servicios, 193, 645);

      //Tipo de Carga

      doc.setFillColor(196, 196, 196);
      doc.rect(40, 660, 495, 10, "F");
      //  doc.setFontType('bold')
      doc.text("Tipo de Carga", 42, 667);
      // doc.setFontType('normal')
      doc.text("Operación de", 42, 687);
      doc.text("Carga", 142, 687);
      if (this.operacion_carga) {
        doc.rect(200, 680, 10, 10, "F");
      } else {
        doc.rect(200, 680, 10, 10);
      }
      doc.text("Descarga", 262, 687);
      if (this.operacion_descarga) {
        doc.rect(342, 680, 10, 10, "F");
      } else {
        doc.rect(342, 680, 10, 10);
      }
      doc.text("Total de Toneladas", 390, 687);
      doc.line(463, 690, 535, 690);
      doc.text(this.total_toneladas, 466, 687);
      doc.text("Tipo de Carga", 42, 702);
      doc.line(142, 705, 535, 705);
      doc.text(this.tipo_carga, 145, 702);

      //API

      doc.setFillColor(196, 196, 196);
      doc.rect(40, 718, 495, 20, "F");
      // doc.setFontType('bold')
      doc.text(
        "Responder aviso (exclusivo Administración Portuaria Integral S.A de C.V).",
        42,
        727
      );
      doc.text(
        "En atención a la solicitud para el uso de infraestructura o atraque en los m uelles se le responde:",
        42,
        735
      );
      // doc.setFontType('normal')
      doc.text("Solicitud de arribo", 42, 750);
      doc.text("Posición de atraque", 191, 750);
      doc.text("Hora de atraque", 392, 750);
      doc.text("Muelle de atraque", 42, 760);
      doc.text("Numero de bita", 191, 760);

      //Comentarios
      //    doc.setFontType('bold')
      doc.text(
        "Asimismo nos hacemos garantes de los gastos durante la estadía que esta embarcación genere",
        42,
        780
      );
      doc.setFillColor(196, 196, 196);
      doc.rect(40, 785, 495, 10, "F");

      doc.text("Programa de actividades del buque en el puerto", 42, 792);
      //    doc.setFontType('normal')
      doc.rect(40, 795, 495, 20);
      doc.text(this.comentarios, 42, 802);

      const pdfOutput = doc.output("blob");

      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfOutput);

      // Open the PDF preview in a new window
      window.open(pdfUrl, "_blank");
    },

    getFiles(files) {
      console.log(files);
    },

    datos() {
      console.log(this.embarcacion);
      this.embarcacionQuery = this.listarEmbarcacion(this.embarcacion);
      console.log(this.embarcacionQuery);
    },

    enviar(
      correo,
      embarcacion,
      procedencia,
      solicitante,
      detalles,
      eta,
      etd,
      muelle,
      status
    ) {
      var template_params = {
        correo: correo,
        procedencia: procedencia,
        embarcacion: embarcacion,
        solicitante: solicitante,
        detalles: detalles,
        eta: eta,
        etd: etd,
        muelle: muelle,
        status: status,
      };

      emailjs.send("gmail", "solicitudUnica", template_params).then(
        function (response) {
          if (response.text === "OK") {
          }
          console.log("SUCCESS. status=%d, text=%s", response.status, response.text);
        },
        function (err) {
          alert("Ocurrió un problema al enviar el correo");
          console.log("FAILED. error=", err);
        }
      );
    },

    enviarSms(embarcacion, solicitante, detalles, muelle, status, telefono) {
      var template_params = {
        embarcacion: embarcacion,
        solicitante: solicitante,
        detalles: detalles,

        muelle: muelle,
        status: status,
        telefono: telefono,
      };

      emailjs.send("gmail", "solicitudUnicaSms", template_params).then(
        function (response) {
          if (response.text === "OK") {
          }
          console.log("SUCCESS. status=%d, text=%s", response.status, response.text);
        },
        function (err) {
          alert("Ocurrió un problema al enviar el correo");
          console.log("FAILED. error=", err);
        }
      );
    },

    emailIn() {
      emailjs.init("user_QA8qMBuyHCQIHTgR0AsPx");
    },
    crearPDF() {
      var quotes = document.getElementById("solicitudGuardia");
      html2canvas(quotes).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 200;
        var pageHeight = 295;

        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm", "a4");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save("solicitudUA.pdf");
      });
    },

    operacionChange() {
      this.carga_descarga = this.operacion_carga;
    },

    mostrarDatosAgencia() {
      this.listarAgencia(this.agencia);
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.embarcacion = item.embarcacion;
      this.solicitud = item.solicitud;
      this.procedencia_solicitud = item.procedencia;
      this.comentarios = item.comentarios;
      this.folioId = item.folio_solicitud;
      this.etaReporte = moment(item.eta).format("MMMM Do YYYY, H:mm");
      this.etdReporte = moment(item.etd).format("MMMM Do YYYY, H:mm");
      this.capitan = item.capitan;
      this.no_tripulantes = item.no_tripulantes;
      this._id = item._id;
      var fechaAprobacion = item.fechaAprobacion;
      var fechaActual = new Date();
      var fechaAprobacionDate = new Date(fechaAprobacion);
      var minutoSumar = 5;

      fechaAprobacionDate.setMinutes(fechaAprobacionDate.getMinutes() + minutoSumar);

      if (fechaActual >= fechaAprobacionDate) {
        this.bandera = true;
      } else {
        this.bandera = false;
      }

      this.fecha_solicitud = moment(item.createdAt).format("MMMM Do YYYY, H:mm");

      var fecha1 = moment(item.eta);
      var fecha2 = moment(item.etd);

      var a = fecha2.diff(fecha1, "days");
      var b = fecha2.diff(fecha1, "hours");
      var horas = b - a * 24;
      var estadia = a + " Dia" + " y " + horas + " Horas";

      //Checkbox
      if (
        item.infraestructura[0] == "Pto. Fijo" ||
        item.infraestructura[1] == "Pto. Fijo" ||
        item.infraestructura[2] == "Pto. Fijo" ||
        item.infraestructura[3] == "Pto. Fijo" ||
        item.infraestructura[4] == "Pto. Fijo" ||
        item.infraestructura[5] == "Pto. Fijo"
      ) {
        this.check1 = "jj";
      }
      if (
        item.infraestructura[0] == "Atraque" ||
        item.infraestructura[1] == "Atraque" ||
        item.infraestructura[2] == "Atraque" ||
        item.infraestructura[3] == "Atraque" ||
        item.infraestructura[4] == "Atraque" ||
        item.infraestructura[5] == "Atraque"
      ) {
        this.check2 = "jj";
      }
      if (
        item.infraestructura[0] == "Cuota por arribo a embarcaciones menores" ||
        item.infraestructura[1] == "Cuota por arribo a embarcaciones menores" ||
        item.infraestructura[2] == "Cuota por arribo a embarcaciones menores" ||
        item.infraestructura[3] == "Cuota por arribo a embarcaciones menores" ||
        item.infraestructura[4] == "Cuota por arribo a embarcaciones menores" ||
        item.infraestructura[5] == "Cuota por arribo a embarcaciones menores"
      ) {
        this.check3 = "jj";
      }
      if (
        item.infraestructura[0] == "Pto. Variable" ||
        item.infraestructura[1] == "Pto. Variable" ||
        item.infraestructura[2] == "Pto. Variable" ||
        item.infraestructura[3] == "Pto. Variable" ||
        item.infraestructura[4] == "Pto. Variable" ||
        item.infraestructura[5] == "Pto. Variable"
      ) {
        this.check4 = "jj";
      }
      if (
        item.infraestructura[0] == "Muellaje" ||
        item.infraestructura[1] == "Muellaje" ||
        item.infraestructura[2] == "Muellaje" ||
        item.infraestructura[3] == "Muellaje" ||
        item.infraestructura[4] == "Muellaje" ||
        item.infraestructura[5] == "Muellaje"
      ) {
        this.check5 = "jj";
      }

      if (
        item.infraestructura[0] == "Embarque y desembarque de pasajeros" ||
        item.infraestructura[1] == "Embarque y desembarque de pasajeros" ||
        item.infraestructura[2] == "Embarque y desembarque de pasajeros" ||
        item.infraestructura[3] == "Embarque y desembarque de pasajeros" ||
        item.infraestructura[4] == "Embarque y desembarque de pasajeros" ||
        item.infraestructura[5] == "Embarque y desembarque de pasajeros"
      ) {
        this.check6 = "jj";
      }

      console.log(item.servicios_portuarios);
      if (
        item.servicios_portuarios[0] == "Piloto" ||
        item.servicios_portuarios[1] == "Piloto" ||
        item.servicios_portuarios[2] == "Piloto" ||
        item.servicios_portuarios[3] == "Piloto" ||
        item.servicios_portuarios[4] == "Piloto" ||
        item.servicios_portuarios[5] == "Piloto" ||
        item.servicios_portuarios[6] == "Piloto" ||
        item.servicios_portuarios[7] == "Piloto" ||
        item.servicios_portuarios[8] == "Piloto"
      ) {
        this.checka = "jj";
      }
      if (
        item.servicios_portuarios[0] == "Amarre de cabos" ||
        item.servicios_portuarios[1] == "Amarre de cabos" ||
        item.servicios_portuarios[2] == "Amarre de cabos" ||
        item.servicios_portuarios[3] == "Amarre de cabos" ||
        item.servicios_portuarios[4] == "Amarre de cabos" ||
        item.servicios_portuarios[5] == "Amarre de cabos" ||
        item.servicios_portuarios[6] == "Amarre de cabos" ||
        item.servicios_portuarios[7] == "Amarre de cabos" ||
        item.servicios_portuarios[8] == "Amarre de cabos"
      ) {
        this.checkb = "jj";
      }
      if (
        item.servicios_portuarios[0] == "Lubricantes" ||
        item.servicios_portuarios[1] == "Lubricantes" ||
        item.servicios_portuarios[2] == "Lubricantes" ||
        item.servicios_portuarios[3] == "Lubricantes" ||
        item.servicios_portuarios[4] == "Lubricantes" ||
        item.servicios_portuarios[5] == "Lubricantes" ||
        item.servicios_portuarios[6] == "Lubricantes" ||
        item.servicios_portuarios[7] == "Lubricantes" ||
        item.servicios_portuarios[8] == "Lubricantes"
      ) {
        this.checkc = "jj";
      }
      if (
        item.servicios_portuarios[0] == "Suministro de agua potable" ||
        item.servicios_portuarios[1] == "Suministro de agua potable" ||
        item.servicios_portuarios[2] == "Suministro de agua potable" ||
        item.servicios_portuarios[3] == "Suministro de agua potable" ||
        item.servicios_portuarios[4] == "Suministro de agua potable" ||
        item.servicios_portuarios[5] == "Suministro de agua potable" ||
        item.servicios_portuarios[6] == "Suministro de agua potable" ||
        item.servicios_portuarios[7] == "Suministro de agua potable" ||
        item.servicios_portuarios[8] == "Suministro de agua potable"
      ) {
        this.checkd = "jj";
      }
      if (
        item.servicios_portuarios[0] == "Combustible" ||
        item.servicios_portuarios[1] == "Combustible" ||
        item.servicios_portuarios[2] == "Combustible" ||
        item.servicios_portuarios[3] == "Combustible" ||
        item.servicios_portuarios[4] == "Combustible" ||
        item.servicios_portuarios[5] == "Combustible" ||
        item.servicios_portuarios[6] == "Combustible" ||
        item.servicios_portuarios[7] == "Combustible" ||
        item.servicios_portuarios[8] == "Combustible"
      ) {
        this.checke = "jj";
      }

      if (
        item.servicios_portuarios[0] == "Almacenaje" ||
        item.servicios_portuarios[1] == "Almacenaje" ||
        item.servicios_portuarios[2] == "Almacenaje" ||
        item.servicios_portuarios[3] == "Almacenaje" ||
        item.servicios_portuarios[4] == "Almacenaje" ||
        item.servicios_portuarios[5] == "Almacenaje" ||
        item.servicios_portuarios[6] == "Almacenaje" ||
        item.servicios_portuarios[7] == "Almacenaje" ||
        item.servicios_portuarios[8] == "Almacenaje"
      ) {
        this.checkf = "jj";
      }
      if (
        item.servicios_portuarios[0] == "Recoleccion de Basura" ||
        item.servicios_portuarios[1] == "Recoleccion de Basura" ||
        item.servicios_portuarios[2] == "Recoleccion de Basura" ||
        item.servicios_portuarios[3] == "Recoleccion de Basura" ||
        item.servicios_portuarios[4] == "Recoleccion de Basura" ||
        item.servicios_portuarios[5] == "Recoleccion de Basura" ||
        item.servicios_portuarios[6] == "Recoleccion de Basura" ||
        item.servicios_portuarios[7] == "Recoleccion de Basura" ||
        item.servicios_portuarios[8] == "Recoleccion de Basura"
      ) {
        this.checkg = "jj";
      }

      if (
        item.servicios_portuarios[0] == "Avituallamiento" ||
        item.servicios_portuarios[1] == "Avituallamiento" ||
        item.servicios_portuarios[2] == "Avituallamiento" ||
        item.servicios_portuarios[3] == "Avituallamiento" ||
        item.servicios_portuarios[4] == "Avituallamiento" ||
        item.servicios_portuarios[5] == "Avituallamiento" ||
        item.servicios_portuarios[6] == "Avituallamiento" ||
        item.servicios_portuarios[7] == "Avituallamiento" ||
        item.servicios_portuarios[8] == "Avituallamiento"
      ) {
        this.checkh = "jj";
      }
      if (
        item.servicios_portuarios[0] == "Reparaciones a flote" ||
        item.servicios_portuarios[1] == "Reparaciones a flote" ||
        item.servicios_portuarios[2] == "Reparaciones a flote" ||
        item.servicios_portuarios[3] == "Reparaciones a flote" ||
        item.servicios_portuarios[4] == "Reparaciones a flote" ||
        item.servicios_portuarios[5] == "Reparaciones a flote" ||
        item.servicios_portuarios[6] == "Reparaciones a flote" ||
        item.servicios_portuarios[7] == "Reparaciones a flote" ||
        item.servicios_portuarios[8] == "Reparaciones a flote"
      ) {
        this.checki = "jj";
      }

      this.estadia = estadia;
      this.muelle = item.muelle;
      this.usuario = item.usuario;
      this.aprobacionApi = item.aprobacionApi;
      this.aprobacionAmls = item.aprobacionAmls;
      this.observaciones = item.observaciones;
      this.operacion_descarga = item.operacion_descarga;
      this.operacion_carga = item.operacion_carga;
      this.tipo_carga = item.tipo_carga;
      this.total_toneladas = item.total_toneladas;
      this.otros_servicios = item.otros_servicios;
      this.detalles = item.comentarios;
      this.agenciasUsuario = item.agencia;
      this.estado = item.estado;
      //this.comprobanteModal = 1;
      this.generarPDF();
    },
    remove(item) {
      this.infraestructura.splice(this.infraestructura.indexOf(item), 1);
      this.infraestructura = [...this.infraestructura];
    },

    mostrarEmbarcacion(id) {
      this.listarEmbarcacion(id);
    },
    ocultarComprobante(item) {
      this.comprobanteModal = 0;
    },
    mostrarNuevo() {
      // this.listarAgencia(this.$store.state.usuario.agencia)
      this.limpiar();
      this.dialogNuevaSolicitud = true;
      this.editedIndex = false;
      this.e1 = 1;
      //this.verNuevo = 1;
    },
    ocultarNuevo() {
      //this.verNuevo = 0;
      this.dialogNuevaSolicitud = false;

      this.limpiar();
    },

    eliminarPasajero(arr, item) {
      let i = arr.indexOf(item);
      if (i != -1) {
        arr.splice(i, 1);
      }
    },

    selectEmbarcaciones() {
      let me = this;
      let embarcacionArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("embarcacion/listActivos", configuracion)
        .then((response) => {
          embarcacionArray = response.data;
          embarcacionArray.map(function (x) {
            me.embarcaciones.push({ text: x.nombre, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    imprimir() {
      const modal = document.getElementById("solicitudGuardia");
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("print");

      if (!section) {
        section = document.createElement("div");
        section.id = "print";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
    },

    listarAgencia(id) {
      let me = this;
      const configuracion = {
        headers: {
          Authorization: `Bearer ` + this.$store.state.token,
        },
      };

      axios
        .get("agencia/query?_id=" + id, configuracion)
        .then(function (response) {
          console.log(response.data);
          me.agenciasUsuario = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    selectAgencias() {
      let me = this;
      let agencaiArray = [];
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("agencia/list", configuracion)
        .then((response) => {
          agencaiArray = response.data;
          agencaiArray.map(function (x) {
            me.agencias.push({ text: x.nombre, value: x._id });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (!this.embarcacion) {
        this.validaMensaje.push("La embarcación es obligatoria");
      }

      if (!this.comentarios) {
        this.validaMensaje.push("El nombre de los detalles es obligatario");
      }
      if (!this.procedencia) {
        this.validaMensaje.push("El nombre de la procedencia es obligatorio");
      }

      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },

    agregarDetalle() {
      this.pasajeros.push({
        nombreCompleto: "",
        identificacion: "",
        libretaMar: "",
        destino: "",
      });
    },

    limpiar() {
      this._id = "";
      this.comentarios = "";
      (this.usuario = ""),
        (this.folio = ""),
        (this.agencia = ""),
        (this.fecha = ""),
        //  this.eta='',
        (this.procedencia = ""),
        (this.embarcacion = "");
      (this.muelle = ""),
        (this.capitan = ""),
        (this.pasajeros = []),
        (this.total_toneladas = ""),
        (this.tipo_Carga = ""),
        (this.operacion_carga = ""),
        (this.servicios_port = []),
        (this.otros_servicios = ""),
        (this.tipo_carga = ""),
        (this.observaciones = "");
      (this.no_tripulantes = ""),
        (this.detalles = ""),
        (this.infraestructura = []),
        (this.valida = 0),
        (this.validaMensaje = []),
        (this.editedIndex = false);
      (this.atraqueReal = ""),
        (this.zarpeReal = ""),
        (this.cargaReal = ""),
        (this.descargaReal = "");
    },
    guardar() {
      let me = this;
      const configuracion = {
        headers: {
          Authorization: `Bearer ` + this.$store.state.token,
        },
      };
      this.loading = true;
      if (this.editedIndex) {
        axios
          .put(
            "solicitudUnica/update",
            {
              _id: this._id,
              embarcacion: this.embarcacion,
              usuario: this.$store.state.usuario._id,
              detalles: this.detalles,
              agencia: this.agencia,
              muelle: this.muelle,
              eta: this.eta,
              etd: this.etd,
              capitan: this.capitan,
              no_tripulantes: this.no_tripulantes,
              comentarios: this.comentarios,
              procedencia: this.procedencia,
              infraestructura: this.infraestructura,
              servicios_portuarios: this.servicios_port,
              otros_servicios: this.otros_servicios,
              operacion_carga: this.operacion_carga,
              operacion_descarga: this.operacion_descarga,
              total_toneladas: this.total_toneladas,
              tipo_carga: this.tipo_carga,
              observaciones: this.observaciones,
            },
            configuracion
          )

          .then(function (response) {
            me.$notify({
              title: "¡Éxito!",
              text: "La operación se completo con éxito",
              type: "success",
            });
            me.close();
            me.e1 = 1;
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.$notify({
              title: "Alerta!",
              text: "Por favor, inténtalo de nuevo o verifica los campos obligatorios.",
              type: "warning",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (this.operacion_carga == "" && this.operacion_descarga == "") {
          (this.operacion_carga = false), (this.operacion_descarga = false);
        }
        if (this.operacion_carga == "") {
          this.operacion_carga = false;
        }
        if (this.operacion_descarga == "") {
          this.operacion_descarga = false;
        }

        axios
          .post(
            "solicitudUnica/add",
            {
              embarcacion: this.embarcacion,
              usuario: this.$store.state.usuario._id,
              detalles: this.detalles,
              muelle: this.muelle,
              agencia: this.agenciasUsuario._id,
              eta: new Date(this.eta),
              etd: new Date(this.etd),
              capitan: this.capitan,
              no_tripulantes: this.no_tripulantes,
              comentarios: this.comentarios,
              procedencia: this.procedencia,
              infraestructura: this.infraestructura,
              servicios_portuarios: this.servicios_port,
              otros_servicios: this.otros_servicios,
              operacion_carga: this.operacion_carga,
              operacion_descarga: this.operacion_descarga,
              total_toneladas: this.total_toneladas,
              tipo_carga: this.tipo_carga,
              observaciones: this.observaciones,
            },
            configuracion
          )
          .then(function (response) {
            me.$notify({
              title: "¡Éxito!",
              text: "La operación se completo con éxito",
              type: "success",
            });
            me.limpiar();
            me.close();
            me.e1 = 1;
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.$notify({
              title: "Alerta!",
              text: "Por favor, inténtalo de nuevo o verifica los campos obligatorios.",
              type: "warning",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    listarUsuario(idSolicitud) {
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let me = this;
      axios
        .get("usuario/query?=" + idServicio, configuracion)
        .then(function (response) {
          me.onhires = response.data;
          me.usuarios_log = me.onhires.usuario;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    obtenerDatosPorFolio() {
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let me = this;
      axios
        .get(
          "solicitudUnica/queryFolio?folio_solicitud=" + this.folioOtraSolicitud,
          configuracion
        )
        .then(function (response) {
          me.llenarFolio(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listar() {
      const configuracion = {
        headers: {
          Authorization: `Bearer ` + this.$store.state.token,
        },
      };

      this.loading = true;
      let me = this;
      axios
        .post("solicitudUnica/list?page=" + this.page, this.arr, configuracion)
        .then(function (response) {
          me.solicitudUnica = response.data.reg;
          me.totalPages = response.data.totalPaginas;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cerrarPrueba() {
      this.adModal = 0;
      this.comprobanteModal = 0;
    },

    listarEmbarcacion(
      correo,
      embarcacion,
      procedencia,
      usuario,
      detalles,
      eta,
      etd,
      muelle,
      estado
    ) {
      let header = { Token: this.$store.state.token };
      let me = this;

      let configuracion = { headers: header };
      axios
        .get("embarcacion/query?_id=" + embarcacion, configuracion)
        .then(function (response) {
          embarcacion = response.data;
          console.log(embarcacion);
          let status;
          if (estado == 0) {
            status = "en proceso";
          } else if (estado == 1) {
            status = "confirmado";
          }
          me.enviar(
            correo,
            embarcacion,
            procedencia,
            usuario,
            detalles,
            eta,
            etd,
            muelle,
            status
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listarSms(embarcacion, usuario, detalles, muelle, estado, telefono) {
      let header = { Token: this.$store.state.token };
      let me = this;

      let configuracion = { headers: header };
      axios
        .get("embarcacion/query?_id=" + embarcacion, configuracion)
        .then(function (response) {
          embarcacion = response.data;
          console.log(embarcacion);
          let status;
          if (estado == 0) {
            status = "en proceso";
          } else if (estado == 1) {
            status = "confirmado";
          }
          me.enviarSms(embarcacion, usuario, detalles, muelle, status, telefono);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listarAgenciaQuery(idA, idE) {
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      let me = this;
      let embarcacion = "";

      axios
        .get("agencia/query?_id=" + idA, configuracion)
        .then(function (response) {
          me.agencias = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get("embarcacion/query?_id=" + idE, configuracion)
        .then(function (response) {
          me.embarcaciones = response.data;

          me.enviar(me.agencias.nombre, me.embarcaciones.nombre);
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    listarEmbarcacionQuery(id) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      this.embarcacionQuery = axios.get("embarcacion/query?_id=" + id, configuracion);
    },

    listarDetalles(id) {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .get("solicitudUnica/query?_id=" + id, configuracion)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    },
    verGuardia(item) {
      this.limpiar();
      this.fecha = item.fecha;
      this.folio = item.folio;
      this.detalles = item.detalles;
      this.agencia = item.agencia._id;
      this.embarcacion = item.embarcacion._id;
      this.listarDetalles(item._id);
      this.verNuevo = 1;
    },

    editItem(item) {
      this.limpiar();
      console.log(item);

      this.listarAgencia(this.$store.state.usuario.agencia);
      this.agencia = item.agencia;

      const etaDate = new Date(item.eta);
      const etdDate = new Date(item.etd);
      const formattedEta = etaDate.toISOString().slice(0, 16);
      const formattedEtd = etdDate.toISOString().slice(0, 16);

      this.eta = formattedEta;
      this.etd = formattedEtd;

      const formattedEta2 = etaDate.toLocaleString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      const formattedEtd2 = etdDate.toLocaleString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      console.log(formattedEta, formattedEta2);
      // Formatear la fecha en el formato YYYY-MM-DDTHH:MM

      // Asignar el valor al input

      this.procedencia = item.procedencia;
      this.comentarios = item.comentarios;
      this.muelle = item.muelle;
      this.embarcacion = item.embarcacion._id;
      this.infraestructura = item.infraestructura;
      this.servicios_port = item.servicios_portuarios;
      this.otros_servicios = item.otros_servicios;

      this.operacion_carga = item.operacion_carga;
      this.operacion_descarga = item.operacion_descarga;
      this.observaciones = item.observaciones;
      this.tipo_carga = item.tipo_carga;
      this.total_toneladas = item.total_toneladas;
      this.listarDetalles(item._id);
      this.capitan = item.capitan;
      this.verNuevo = 1;
      this._id = item._id;
      this.no_tripulantes = item.no_tripulantes;
      this.editedIndex = true;
      this.dialog = false;
      this.dialogNuevaSolicitud = true;
      //this.verNuevo = 1;
    },

    llenarFolio(item) {
      this.limpiar();
      this.listarAgencia(this.$store.state.usuario.agencia);
      this.agencia = item.agencia;
      var etaDate = item.eta;
      this.eta = etaDate;
      var etdDate = item.etd;
      this.etd = etdDate;

      this.procedencia = item.procedencia;
      this.comentarios = item.comentarios;
      this.muelle = item.muelle;
      this.embarcacion = item.embarcacion._id;
      this.infraestructura = item.infraestructura;
      this.servicios_port = item.servicios_portuarios;
      this.otros_servicios = item.otros_servicios;

      this.operacion_carga = item.operacion_carga;
      this.operacion_descarga = item.operacion_descarga;
      this.observaciones = item.observaciones;
      this.tipo_carga = item.tipo_carga;
      this.total_toneladas = item.total_toneladas;
      this.listarDetalles(item._id);
      this.capitan = item.capitan;

      this._id = item._id;
      this.no_tripulantes = item.no_tripulantes;
    },

    activarDesactivarMostrar(accion, item, folio) {
      this.adModal = 1;
      this.adNombre = folio.folioId;
      this.folio = folio;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else if (accion == 3) {
        this.adAccion = 3;
      } else if (accion == 4) {
        this.adAccion = 4;
      } else if (accion == 5) {
        this.adAccion = 5;
      } else {
        this.adModal = 0;
      }
    },
    activar() {
      let me = this;
      this.loading = true;
      const configuracion = {
        headers: {
          Authorization: `Bearer ` + this.$store.state.token,
        },
      };
      axios
        .put(
          "solicitudUnica/aut1_solicitud_unica_email",
          {
            _id: this.itemSelected._id,
            // aprobacionAmls: this.$store.state.usuario.nombre,
          },
          configuracion
        )

        .then(function (response) {
          me.$notify({
            title: "¡Éxito!",
            text: "Solicitud confirmada con exito.",
            type: "success",
          });
          me.dialogAut1 = false;
          me.itemSelected = [];

          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    autorizarOpib() {
      let me = this;
      this.loading = true;
      const configuracion = {
        headers: {
          Authorization: `Bearer ` + this.$store.state.token,
        },
      };
      axios
        .put(
          "solicitudUnica/autorizar_opib",
          {
            _id: this.itemSelected._id,
          },
          configuracion
        )

        .then(function (response) {
          me.$notify({
            title: "¡Éxito!",
            text: "La operación se completo con éxito",
            type: "success",
          });
          me.dialogAut2 = false;
          me.itemSelected = [];

          me.listar();
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    desactivar() {
      let me = this;
      let header = { Token: this.$store.state.token };
      let configuracion = { headers: header };
      axios
        .put(
          "solicitudUnica/deactivate",
          {
            _id: this._id,
            aprobacionAmls: this.$store.state.usuario.nombre,
            motivo_cancelacion: this.motivo_cancelacion,
          },
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.comprobanteModal = 0;
          me.listar();
          me.listarEmbarcacionCancelada(
            response.data.embarcacion,
            response.data.procedencia,
            response.data.usuario.nombre,
            response.data.comentarios,
            moment(response.data.eta).format("MMMM Do YYYY, h:mm a"),
            moment(response.data.etd).format("MMMM Do YYYY, h:mm a"),
            response.data.muelle,
            status,
            me.motivo_cancelacion,
            response.data.usuario.email
          );
          me.$notify({
            title: "Solicitud Cancelada!",
            text: "Solicitud Cancelada con exito",
            type: "success",
          });
          (me.adAccion = 0), (me.adNombre = ""), (me.adId = "");
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    finalizarServicio() {
      let me = this;
      let header = { Token: this.$store.state.token };
      var usuario = "";
      if (!this.atraqueReal || !this.zarpeReal || !this.cargaReal || !this.descargaReal) {
        me.$notify({
          title: "Error",
          text: "Por favor, inténtalo de nuevo o verifica los campos obligatorios.",
          type: "error",
        });
        return;
      }
      let atraque = new Date(this.atraqueReal);
      let zarpe = new Date(this.zarpeReal);
      let configuracion = { headers: header };
      axios
        .put(
          "solicitudUnica/finalizarServicio",
          {
            _id: this._id,
            atraque: atraque,
            zarpe: zarpe,
            carga: this.cargaReal,
            descarga: this.descargaReal,
          },
          configuracion
        )

        .then(function (response) {
          swal(
            "Solicitud Finalizada",
            "La solicitud se ha guardado como realizada",
            "success"
          );
          me.ocultarComprobante();
          (me.adModal = 0),
            (me.adAccion = 0),
            (me.adNombre = ""),
            (me.adId = ""),
            me.listar();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    close() {
      this.dialog = false;
    },

    prueba() {
      console.log(this.eta);
      console.log(this.etd);
    },

    mostrarDialogAut1(item) {
      this.itemSelected = item;
      this.dialogAut1 = true;
    },

    mostrarDialogAut2(item) {
      this.itemSelected = item;
      this.dialogAut2 = true;
    },

    async imprimirPaseAcceso(item) {
      const datos = {
        embarcacion: item.embarcacion.nombre,
        muelle: item.muelle,
        folio: item.folio_solicitud,
        _id: item._id,
        agencia: item.agencia.nombre,
      };
      console.log(datos);
      generateAcces(datos);
    },

    mostrarDialogReprogramar(item) {
      this.dialogReprogramacion = true;
      this.itemSelected = item;
    },

    async reprogramarSolicitudUpdate() {
      if (!this.eta_reprog || !this.etd_reprog) {
        this.$notify({
          title: "Alerta!",
          text: "Por favor, inténtalo de nuevo o verifica los campos obligatorios.",
          type: "warning",
        });

        return;
      }
      const datos = {
        eta: this.eta_reprog,
        etd: this.etd_reprog,
        _id: this.itemSelected._id,
      };

      this.loading = true;

      await reprogramarSolicitud(datos)
        .then(() => {
          this.$notify({
            title: "¡Éxito!",
            text: "La operación se completo con éxito",
            type: "success",
          });
          this.itemSelected = [];
          this.listar();
          this.eta_reprog = "";
          this.etd_reprog = "";
          this.dialogReprogramacion = false;
        })
        .catch((err) => {
          me.$notify({
            title: "Alerta!",
            text: "Por favor, inténtalo de nuevo o verifica los campos obligatorios.",
            type: "warning",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    checkDataStepper(step) {
      const stepsFields = {
        1: [
          this.embarcacion,
          this.capitan,
          this.procedencia,
          this.muelle,
          this.eta,
          this.etd,
          this.no_tripulantes,
        ],
        2: [],
        3: [],
      };

      const requiredFields = stepsFields[step];
      const isAnyFieldMissing = requiredFields.some((field) => !field);

      if (isAnyFieldMissing) {
        this.text = "Todos los campos son obligatorios";
        this.snackbar = true;
      } else {
        this.e1 = step + 1; // Avanza al siguiente paso
      }
    },
    filtroLabelPush(tipo) {
      if (tipo == "folio") {
        let existe = this.arr.findIndex((e) => e.field == "folio");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "folio_solicitud",
          value: this.folio_filter,
          label: "Folio : " + this.folio_filter,
          collection: "rf",
        });
      } else if (tipo == "embarcacion") {
        let existe = this.arr.findIndex((e) => e.field == "embarcacion");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "embarcacion",
          value: this.embarcacion_filter.value,
          label: "Embarcación: " + this.embarcacion_filter.text,
          collection: "embarcacion",
        });
      } else if (tipo == "procedencia") {
        let existe = this.arr.findIndex((e) => e.field == "procedencia");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "procedencia",
          value: this.procedencia_filter,
          label: "Procedencia : " + this.procedencia_filter,
          collection: "rf",
        });
      } else if (tipo == "muelle") {
        let existe = this.arr.findIndex((e) => e.field == "muelle");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "muelle",
          value: this.muelle_filter,
          label: "Muelle : " + this.muelle_filter,
          collection: "rf",
        });
      } else if (tipo == "estado") {
        let existe = this.arr.findIndex((e) => e.field == "estado");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "estado",
          value: this.estatus_filter.value,
          label: "Estatus : " + this.estatus_filter.text,
          collection: "rf",
        });
      } else if (tipo == "eta") {
        const fecha_inicio = new Date(this.eta_inicio_filter);
        const fecha_fin = new Date(this.eta_fin_filter);
        let existe = this.arr.findIndex((e) => e.field == "eta");
        if (existe >= 0) {
          this.arr.splice(existe, 1);
        }
        this.arr.push({
          field: "eta",
          value: {
            $gte: fecha_inicio,
            $lte: fecha_fin,
          },
          label: "Entre : " + this.eta_inicio_filter + " y " + this.eta_fin_filter,
          collection: "rf",
        });
      }

      this.showFilterFolio = false;
      this.showFilterEmbarcacion = false;
      this.showFilterProcedencia = false;
      this.showFilterMuelle = false;
      this.showFilterEstatus = false;
      this.showFilterFecha = false;
      this.listar();
      // this.showFilterFecha = false;
      // this.showFilterOperador = false;
      // this.showFilterTipoUnidad = false;
    },

    eliminarFiltro(index) {
      this.arr.splice(index, 1);
      this.listar();
    },

    async generarPdfBackend(item) {
      this.loading = true;

      await generarPdfSU(item)
        .then((e) => {
          const base64PDF = e.data;
          const blob = this.base64ToBlob(base64PDF, "application/pdf");
          const url = URL.createObjectURL(blob);
          // Abre el PDF en una nueva ventana
          window.open(url, `_blank`);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    base64ToBlob(base64, contentType) {
      const byteCharacters = atob(base64);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },
  },
};
</script>
<style>
#agencia {
  margin-left: 5%;
  margin-right: 8%;
}

@media print {
  #cambioGuardia {
    display: table;
    table-layout: fixed;
    padding-top: 2.5cm;
    padding-bottom: 2.5cm;
    height: auto;
  }
}

#factura {
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

#table_agencia {
  margin-right: 10px;
}

#imagen {
  width: 100px;
}

#imagen2 {
  width: 90px;
}

#fact {
  font-size: 14px;

  text-align: center;
}

#datos {
  float: left;
  margin-top: 0%;
  margin-left: 15%;
  margin-right: 2%;
  /*text-align: justify;*/
}

#encabezado {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
}

section {
  clear: left;
}

#cliente {
  text-align: left;
}

#facliente {
  width: 40%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 15px;
}

#fa {
  color: #ffffff;
  font-size: 14px;
}

#fa2 {
  color: #ffffff;
  font-size: 14px;
}

#facarticulo {
  width: 90%;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 20px;
  margin-bottom: 15px;
  border: 0.5px solid #ddd;
}

#facarticulo thead {
  padding: 20px;
  background: #31467c;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

#solicitante {
  width: 60%;
  border-collapse: collapse;
  border-spacing: 1;
  padding: 20px;
  margin-bottom: 15px;
  border: 0.5px solid #ddd;
}

#solicitante thead {
  padding: 20px;
  background: #31467c;
  text-align: center;

  border-bottom: 1px solid #ffffff;
}

#gracias {
  text-align: center;
}

#tituloSolicitante {
  background-color: #808285;
  border: 1px solid #ddd;
  color: #ffffff;
}

#tituloPasajeros {
  background-color: #808285;
  border: 1px solid #ddd;
  color: #ffffff;
}

#texto {
  color: black;
}

.custom-table-header .v-data-table-header {
  background-color: #7c1c44; /* Fondo verde */
  color: white; /* Color del texto */
}

.custom-table-header th {
  color: white !important; /* Fuerza el color del texto */
  font-weight: bold;
}

.custom-table-header .v-data-table-header th:first-child {
  border-radius: 10px 0 0 0; /* Esquina superior izquierda */
}

.custom-table-header .v-data-table-header th:last-child {
  border-radius: 0 10px 0 0; /* Esquina superior derecha */
}

.input-filter {
  background-color: rgb(241, 241, 241);
  border-radius: 20px;
  padding: -5px;
  width: 100%;
  height: 40px;
}

.input-filter_seguimiento {
  background-color: rgb(226, 226, 226);
  border-radius: 20px;
  padding: -45px;
  width: 100%;
  height: 25px;
}

.input-filter_title {
  background-color: rgb(29, 29, 29);
  border-radius: 20px;
  padding: -45px;
  color: white;
  width: 100%;
  text-align: center;
  height: 25px;
}

.button-fab {
  bottom: 50px;
  height: 60px;
  position: fixed;
  right: 20px;
  width: 60px;
}

.filter-label {
  color: #b1adad;
  margin-top: -35px;
  margin-left: 40px;
  font-size: 13px;
  font-weight: normal;
}

.filter-chips {
  color: #b1adad;
  padding: 5px;
  margin-top: -41px;
  margin-left: 40px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.v-input--select .v-label {
  font-size: 2px;
}
</style>
