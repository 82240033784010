<template>
  <v-app id="app">
    <template>
      <v-app-bar absolute color="#7c1c44" dark elevate-on-scroll>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <span style="font-weight: bold; font-size: 15px"> SAM</span>
        <v-spacer />

        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          class="my-1"
          v-if="logueado"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small color="white" v-bind="attrs" v-on="on">
              <v-icon color="black"> person </v-icon>
            </v-btn>
          </template>

          <v-card class="">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <img
                    :src="`https://ui-avatars.com/api?name=${$store.state.usuario.nombre}`"
                    alt="John"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ $store.state.usuario.nombre }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $store.state.usuario.email }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <!-- <v-list dense>
              <v-list-item>
                <v-list-item-action>
                  <v-btn small @click="salir()" icon v-if="logueado">
                    <v-icon small>logout</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-btn small @click="salir()" icon v-if="logueado">
                    <v-icon small>logout</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-title>Configuración</v-list-item-title>
              </v-list-item>
            </v-list> -->
            <v-list dense>
              <v-list-item-group color="primary">
                <v-list-item
                  v-if="
                    $store.state.usuario.rol == 'Mesa de control' ||
                    $store.state.usuario.rol == 'root'
                  "
                  @click="menu = false"
                  :to="{ name: 'configuracion' }"
                >
                  <v-list-item-icon>
                    <v-icon small>settings</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Configuración</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="salir()">
                  <v-list-item-icon>
                    <v-icon small>logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Cerrar sesión</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>

        <!-- <v-btn :to="{ name: 'login' }" icon v-else>
          <v-icon>fingerprint</v-icon>
        </v-btn> -->
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" absolute temporary v-if="logueado">
        <v-list dense nav>
          <v-list-item two-line :class="miniVariant && 'px-0'">
            <v-list-item-avatar>
              <img
                className="h-150 w-150 rounded-full"
                :src="`https://ui-avatars.com/api?name=${$store.state.usuario.nombre}`"
                alt="jcan"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ $store.state.usuario.nombre }}</v-list-item-title
              ><br />
              <v-list-item-subtitle>{{
                $store.state.usuario.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <template>
            <v-list-item :to="{ name: 'home' }">
              <v-list-item-action>
                <v-icon>dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template v-if="esRoot">
            <v-list-item :to="{ name: 'solicitud_unica_admin' }">
              <v-list-item-action>
                <v-icon>today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Solicitud Unica de Acceso</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'solicitudes' }">
              <v-list-item-action>
                <v-icon>people</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >Solicitud de Embarque y <br />Desembarque de
                  Personal</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'vehiculosPersonalAdmin' }">
              <v-list-item-action>
                <v-icon>local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Acceso a Vehiculos y Personal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'movimientosamls' }">
              <v-list-item-action>
                <v-icon>timeline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Movimientos de Agenciamiento</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

          <template v-if="esAgencia || esRoot || esVisor">
            <v-list-item :to="{ name: 'solicitudUnicaAgencia' }">
              <v-list-item-action>
                <v-icon>today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Solicitud Unica de Acceso</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'cambiosGuardiaAgencia' }">
              <v-list-item-action>
                <v-icon>people</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Embarque/Desembarque</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'vehiculosPersonalAgencia' }">
              <v-list-item-action>
                <v-icon>local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Vehiculos / Personal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>

          <!-- <template v-if="esAgencia">
            <v-list-item :to="{ name: 'misPasajerosAgencia' }">
              <v-list-item-action>
                <v-icon>directions_walk</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Mis Pasajeros</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

          <template v-if="esAdministrador || esOpib || esOperaciones">
            <v-list-item :to="{ name: 'solicitudUnicaAgencia' }">
              <v-list-item-action>
                <v-icon>today</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Solicitud Unica de Acceso</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'cambiosGuardiaAgencia' }">
              <v-list-item-action>
                <v-icon>people</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Embarque/Desembarque</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'vehiculosPersonalAgencia' }">
              <v-list-item-action>
                <v-icon>local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Vehiculos / Personal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- <template v-if="esRoot || esVigilante">
            <v-list-item :to="{ name: 'bitacora' }">
              <v-list-item-action>
                <v-icon>assignment_ind</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Pase de Lista</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

          <!-- <template v-if="esRoot">
            <v-list-item :to="{ name: 'clientes' }">
              <v-list-item-action>
                <v-icon>person_add</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Clientes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

          <template v-if="esRoot || esAdministrador || esVisor">
            <v-list-item :to="{ name: 'embarcacion' }">
              <v-list-item-action>
                <v-icon>directions_boat</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Embarcaciones</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="esAPITAB">
            <v-list-item :to="{ name: 'solicitud_apitab' }">
              <v-list-item-action>
                <v-icon>work</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Solicitud Unica de Arribo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="esAPITAB">
            <v-list-item :to="{ name: 'cambio_guardia_api' }">
              <v-list-item-action>
                <v-icon>people</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Solicitud de Embarque y <br />Desembarque de Personal</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'vehiculos_personal_api' }">
              <v-list-item-action>
                <v-icon>local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Solicitud de Vehiculos y
                  <br />Personal
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="esAPITAB">
            <v-list-item :to="{ name: 'pspcosteo' }">
              <v-list-item-action>
                <v-icon>engineering</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>PSP Costeo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="esRoot || esCliente || esAdministrador || esOpib || esVisor">
            <v-list-item :to="{ name: 'agencia' }">
              <v-list-item-action>
                <v-icon>work</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Agencias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="esRoot || esAdministrador || esComercial || esVisor">
            <v-list-item :to="{ name: 'alta_psp' }">
              <v-list-item-action>
                <v-icon>groups</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>PSP</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>

          <!-- <template v-if="esRoot">
            <v-list-item :to="{ name: 'estadias' }">
              <v-list-item-action>
                <v-icon>edit_calendar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Estadia</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

          <template v-if="esCliente">
            <v-list-item :to="{ name: 'solicitud_unica' }">
              <v-list-item-action>
                <v-icon>today</v-icon>
              </v-list-item-action>
              <v-list-item-title>Solicitud Unica de Arribo</v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="esVigilante || esRoot">
            <v-list-item :to="{ name: 'gestion_acceso' }">
              <v-list-item-action>
                <v-icon>qr_code_scanner</v-icon>
              </v-list-item-action>
              <v-list-item-title> Gestión de acceso </v-list-item-title>
            </v-list-item>
          </template>

          <template
            v-if="esPSP || esFinanzas || esRoot || esOpib || esOperaciones || esVisor"
          >
            <v-list-item :to="{ name: 'accesosPsp' }">
              <v-list-item-action>
                <v-icon>local_shipping</v-icon>
              </v-list-item-action>
              <v-list-item-title> Acceso a PSP </v-list-item-title>
            </v-list-item>
          </template>
          <!-- 
          <template v-if="esCliente">
            <v-list-item :to="{ name: 'pasajeros' }">
              <v-list-item-action>
                <v-icon>directions_walk</v-icon>
              </v-list-item-action>
              <v-list-item-title>Mis Pasajeros</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'reportes_admin' }">
              <v-list-item-action>
                <v-icon>picture_as_pdf</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Reportes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->

          <template v-if="esMesaControl || esAdministrador || esRoot">
            <v-list-item :to="{ name: 'bitacora_agenciamiento' }">
              <v-list-item-action>
                <v-icon>directions_walk</v-icon>
              </v-list-item-action>
              <v-list-item-title>Bitacora </v-list-item-title>
            </v-list-item>
          </template>

          <template v-if="esRoot">
            <v-list-item :to="{ name: 'usuario' }">
              <v-list-item-action>
                <v-icon>group_add</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item :to="{ name: 'altapsp' }">
              <v-list-item-action>
                <v-icon>manage_accounts</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Alta PSP</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </template>
          <v-divider></v-divider>
          <template v-if="!esSoporte">
            <v-list-item :to="{ name: 'soporte' }">
              <v-list-item-action>
                <v-icon>confirmation_number</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Soporte técnico</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="esSoporte">
            <v-list-item :to="{ name: 'atencion_tickets' }">
              <v-list-item-action>
                <v-icon>confirmation_number</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Tickets</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list> </v-navigation-drawer
      ><br /><br /><br /><br />

      <v-main>
        <div class="mx-6">
          <notifications position="bottom right" />

          <v-slide-y-transition mode="out-in">
            <router-view />
          </v-slide-y-transition>
        </div>
      </v-main>
    </template>

    <!-- <template v-if="!logueado">
      <div class="block TutorialBlock my-5">
        <v-container>
          <h2 class="text-center" style="">Videotutoriales</h2>
          <v-row>
            <v-col sm="12" md="4" lg="4">
              <v-card outlined class="mx-auto">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="https://i.ibb.co/JdnVcXq/su.jpg"
                >
                </v-img>

                <v-card-subtitle class="pb-0"> Tutorial 1 </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div><strong>Solicitud unica de acceso</strong></div>

                  <div>
                    En este tutorial se muestra un ejemplo de como realizar una solicitud
                    para el acceso de embarcaciones al muelle
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="dark"
                    text
                    href="https://youtu.be/ZCc3WNTFfqk"
                    target="_blank"
                  >
                    Ir al tutorial
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col sm="12" md="4" lg="4">
              <v-card class="mx-auto">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="https://i.ibb.co/9YPQp8F/cg.jpg"
                >
                </v-img>

                <v-card-subtitle class="pb-0"> Tutorial 2 </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div><strong>Embarque y Desembarque de Personal</strong></div>

                  <div>
                    En este tutorial se muestra un ejemplo como ingresar una soliitud de
                    embarque o desembarque de pasajeros
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="dark"
                    text
                    href="https://youtu.be/IvBNpOhz1ks"
                    target="_blank"
                  >
                    Ir al tutorial
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col sm="12" md="4" lg="4">
              <v-card class="mx-auto">
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="https://i.ibb.co/4KnhtV9/gmgg.jpg"
                >
                </v-img>

                <v-card-subtitle class="pb-0"> Tutorial 3 </v-card-subtitle>

                <v-card-text class="text--primary">
                  <div><strong>Acceso a Vehiculos y Personal</strong></div>

                  <div>
                    En este tutorial se muestra un ejemplo de como ingresar una solicitud
                    de acceso a vehiculos y personal
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="black"
                    text
                    href="https://youtu.be/0jQuAxVldMk"
                    target="_blank"
                  >
                    Ir al tutorial
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template> -->

    <template v-if="!logueado">
      <!-- Footer -->
      <!-- <mdb-footer style="backgroundcolor: gray" dark class="page-footer font-small pt-0">
        <div style="backgroundcolor: #41474a">
          <mdb-container class="text-left">
            <mdb-row class="py-4 d-flex align-items-center">
              <mdb-col md="6" lg="5" class="text-center text-md-left mb-4 mb-md-0">
                <h3 class="mb-0" style="color: white">
                  ¡Conéctate con nosotros en las redes sociales!
                </h3>
              </mdb-col>
              <mdb-col md="6" lg="7" class="text-center text-md-right">
                <a
                  href="https://www.facebook.com/108769701013839/posts/108787737678702/"
                  class="fb-ic ml-0"
                  ><i class="fa fa-facebook white-text mr-lg-4" style="color: white"> </i
                ></a>
                <a
                  href="https://www.linkedin.com/company/amls-agency-vessels/"
                  class="li-ic"
                  ><i class="fa fa-linkedin white-text mr-lg-4" style="color: white"> </i
                ></a>
                <a class="ins-ic"
                  ><i class="fa fa-instagram white-text mr-lg-4" style="color: white"> </i
                ></a>
                <a class="wa.link/7ay3uy"
                  ><i class="fa fa-whatsapp white-text mr-lg-4" style="color: white"> </i
                ></a>
              </mdb-col>
            </mdb-row>
            <v-divider></v-divider>
          </mdb-container>
        </div>
        <mdb-container class="mt-5 mb-4 text-center text-md-left">
          <mdb-row class="mt-3">
            <mdb-col md="6" lg="6" xl="4" class="mb-6">
              <h6
                class="text-uppercase font-weight-bold"
                style="color: black; font-size: 18px"
              >
                <strong>Contacto</strong>
              </h6>
              <hr
                class="deep-white accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style="width: 70px"
              />
              <p style="color: black">
                Para garantizar una respuesta rápida y eficiente a cualquier solicitud de
                nuestros clientes, nuestras oficinas operan las 24 horas del día, los 7
                días de la semana, los 365 días del año con un equipo de trabajo
                experimentado.
              </p>
            </mdb-col>
            <mdb-col md="2" lg="2" xl="2" class="mb-4">
              <h6 class="text-uppercase font-weight-bold">
                <strong>Servicios</strong>
              </h6>
              <hr
                class="deep-white accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px"
              />
              <p>Agencia Consignataria</p>
              <p>Fletamento de Embarcaciones</p>
              <p>Administranción de Muelles</p>
            </mdb-col>
            <mdb-col md="4" lg="3" xl="3" class="mb-4">
              <h6 class="text-uppercase font-weight-bold">
                <strong>Dos Bocas</strong>
              </h6>
              <hr
                class="deep-white accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style="width: 60px"
              />
              <p>
                <i class="fa fa-home mr-3"></i>Carretera Paraíso Dos Bocas No. 924
                <br />Colonia El Limon, C.P. 86606
              </p>
              <p><i class="fa fa-envelope mr-3"></i> contacto@amls.mx</p>
              <p><i class="fa fa-phone mr-3"></i> 933 596 7068</p>
            </mdb-col>
            <mdb-col md="4" lg="3" xl="3" class="mb-4">
              <h6 class="text-uppercase font-weight-bold">
                <strong>Ciudad del Carmen</strong>
              </h6>
              <hr
                class="deep-black accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style="width: 120px"
              />
              <p>
                <i class="fa fa-home mr-3"></i> Calle 4 Lote 5 Local C y D P.I.P
                <br />
                Laguna Azul C.P. 24129
              </p>
              <p><i class="fa fa-envelope mr-3"></i> contacto@amls.mx</p>
              <p><i class="fa fa-phone mr-3"></i> 938 118 4904</p>
            </mdb-col>
          </mdb-row>
        </mdb-container>
        <div class="footer-copyright text-center py-3">
          <mdb-container fluid>
            &copy; 2024 Copyright: <a href="https://www.amls.mx"> amls.mx </a>
          </mdb-container>
        </div>
      </mdb-footer> -->
      <!-- Footer -->
    </template>
  </v-app>
</template>

<script>
"use strict";
import { mdbFooter, mdbContainer, mdbRow, mdbCol } from "mdbvue";
export default {
  name: "App",

  components: {
    mdbFooter,
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  data() {
    return {
      drawer: false,
      dialog: false,
      dialogPsp: false,
      miniVariant: "",
      fav: true,
      menu: false,
      message: false,
      hints: true,
    };
  },
  computed: {
    logueado() {
      return this.$store.state.usuario;
    },
    esAdministrador() {
      return (
        this.$store.state.usuario && this.$store.state.usuario.rol == "Administrador"
      );
    },
    esRoot() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "root";
    },
    esCliente() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Cliente";
    },
    esAPITAB() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "APITAB";
    },
    esPSP() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "PSP";
    },
    esVigilante() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Vigilante";
    },
    esAgencia() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Agencia";
    },
    esOpib() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "OPIP";
    },
    esMesaControl() {
      return (
        this.$store.state.usuario && this.$store.state.usuario.rol == "Mesa de control"
      );
    },
    esVisor() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Lectura";
    },
    esSoporte() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Soporte";
    },
    esOperaciones() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Operaciones";
    },
    esFinanzas() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Finanzas";
    },
    esComercial() {
      return this.$store.state.usuario && this.$store.state.usuario.rol == "Comercial";
    },
  },
  created() {
    this.$store.dispatch("autoLogin");
  },
  methods: {
    salir() {
      this.$store.dispatch("salir");
      this.menu = false;
    },
  },
};
</script>

<style></style>
